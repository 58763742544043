<template>
  <vx-card style="padding-left: 10px" id="editPage">
    <div style="float: right">
      <vs-button @click="saveData" icon-pack="feather" class="m-2"
        >Save Details</vs-button
      >
    </div>

    <!-- <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-row vs-type="flex" vs-align="left" class="w-full">
        <div class="w-full m-5">
          <h5>Account Details</h5>
        </div>
      </vs-row>

       <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-row vs-type="flex" vs-align="left" class="w-full">
            <vs-input
              :success="!errors.first('basic.email') && clinicData.email != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.email') ? true : false"
              v-validate="'required|email'"
              name="email"
              data-vv-as="email"
              label="Email"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.email')"
              v-model="clinicData.email"
            />
          </vs-row>
          <vs-row vs-type="flex" vs-align="left" class="w-full">
            <vx-tooltip
              v-if="clinicData.emailVerifiedAt"
              text="Verified"
              style="display: flex"
            >
              <feather-icon
                style="color: rgba(var(--vs-primary), 1) !important"
                icon="CheckCircleIcon"
                svgClasses="h-5 w-5"
                class="mr-2 cursor-pointer"
              ></feather-icon>
            </vx-tooltip>
            <vx-tooltip v-else text="Unverified" style="display: flex">
              <feather-icon
                style="color: rgba(var(--vs-danger), 1) !important"
                icon="AlertCircleIcon"
                svgClasses="h-5 w-5"
                class="mr-2 cursor-pointer"
              ></feather-icon>
            </vx-tooltip>
            <VerifyEmail
              @onSuccessfullyVerified="onSuccessfullyVerified"
              v-if="!clinicData.emailVerifiedAt"
              :userId="clinicId"
            />
          </vs-row>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5 mt-8 items-center flex">
          <vs-button
            v-if="check_has_permission('changeClinicPassword')"
            @click="resetPasswordHandler"
            icon-pack="feather"
            class="m-2"
            >Change Password</vs-button
          >
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('basic.firstName') && clinicData.firstName != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.firstName') ? true : false"
            v-validate="'required'"
            name="firstName"
            data-vv-as="first name"
            label="First Name"
            class="w-full"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.firstName')"
            v-model="clinicData.firstName"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('basic.lastName') && clinicData.lastName != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.lastName') ? true : false"
            v-validate="'required'"
            name="lastName"
            data-vv-as="last name"
            label="Last Name"
            class="w-full"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.lastName')"
            v-model="clinicData.lastName"
          />
        </div>
      </vs-col>
    </vs-row> -->

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-row vs-type="flex" vs-align="left" class="w-full">
        <div class="w-full m-5">
          <h5>Organization Details</h5>
        </div>
      </vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5 flex flex-no-wrap items-end">
          <vs-input
            :success="
              !errors.first('basic.name') && organizationDetail.name != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.name') ? true : false"
            v-validate="'required'"
            name="organizationName"
            :danger-text="errors.first('basic.name')"
            data-vv-as="ABN"
            label="Organization Name"
            class="w-full"
            data-vv-scope="basic"
            v-model="organizationDetail.name"
            disabled
          />
          <change-org
            style="float: left;"
            class="ml-1 mb-1"
            :clinicId="clinicId"
            :clinicData="clinicData"
            :currentOrg="organizationDetail._id"
            @refetchClinic="getClinicDetail"
          />
          <vs-button
            class="ml-1 mb-1"
            type="flat"
            icon-pack="feather"
            icon="icon-external-link"
            icon-after
            @click="openOrganization"
          >
            View
          </vs-button>
        </div>
      </vs-col>

      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('basic.abn') && organizationDetail.abn != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.abn') ? true : false"
            v-validate="'required'"
            name="abn"
            :danger-text="errors.first('basic.abn')"
            data-vv-as="ABN"
            label="ABN"
            class="w-full"
            data-vv-scope="basic"
            v-model="organizationDetail.abn"
            disabled
          />
        </div>
      </vs-col> -->
    </vs-row>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-row vs-type="flex" vs-align="left" class="w-full">
        <div class="w-full m-5">
          <h5>Clinic Details</h5>
        </div>
      </vs-row>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('basic.clinicName') && clinicData.clinicName != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.clinicName') ? true : false"
            v-validate="'required'"
            name="clinicName"
            :danger-text="errors.first('basic.clinicName')"
            data-vv-as="clinic name"
            label="Clinic Name"
            class="w-full"
            data-vv-scope="basic"
            v-model="clinicData.clinicName"
          />
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('bestContactNumber') ? true : false"
            :success="
              !errors.first('bestContactNumber') && clinicData.bestContactNumber != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            name="phoneNumber"
            label="Phone Number (Optional)"
            v-model="clinicData.bestContactNumber"
            class="w-full"
          />
          <span class="text-danger text-sm error-text">{{
            errors.first("bestContactNumber")
          }}</span>
        </div>
      </vs-col>

      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6" v-if="region !== 'US'">
        <div class="w-full m-5">
        <vs-input
          :danger="errors.first('basic.address') ? true : false"
          :danger-text="errors.first('basic.address')"
          data-vv-scope="basic"
          :success="
            !errors.first('basic.address') &&
            clinicData.address.displayAddress != ''
          "
          ref="autocomplete"
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required'"
          data-vv-as="address"
          name="address"
          label="Clinic Address"
          placeholder="Clinic address"
          v-model="clinicData.address.displayAddress"
          class="w-full"
        />
        </div>
      </vs-col> -->

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6" >
        <div class="w-full m-5">
          <span style="font-size:.85rem">Clinic Address</span>
          <div id="geocoder"/>
        </div>
      </vs-col>

      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <div
            class="
              vs-component vs-con-input-label vs-input
              w-full
              vs-input-primary
            "
            :class="{
              'input-icon-validate-danger':
                errors.first('displayAddress') ||
                clinicData.address.displayAddress ||
                checkDisplayAddress != clinicData.address.displayAddress,
              'input-icon-validate-success':
                checkDisplayAddress == clinicData.address.displayAddress,
            }"
          >
            <label for class="vs-input--label">Clinic Street Address</label>
            <div class="vs-con-input">
              <input
                type="text"
                placeholder
                ref="autocomplete"
                v-validate="'required'"
                data-vv-validate-on="blur"
                data-vv-as="clinic street address"
                name="displayAddress"
                v-model="clinicData.address.displayAddress"
                class="vs-inputx vs-input--input normal"
                :class="{ hasValue: clinicData.address.displayAddress }"
                :danger-text="'asd'"
                style="border: 1px solid rgba(0, 0, 0, 0.2)"
              />
              <span class="input-icon-validate vs-input--icon-validate">
                <i
                  v-if="
                    checkDisplayAddress == clinicData.address.displayAddress
                  "
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >done</i
                >
                <i
                  v-else-if="
                    errors.first('displayAddress') ||
                    clinicData.address.displayAddress ||
                    checkDisplayAddress != clinicData.address.displayAddress
                  "
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >error</i
                >
              </span>
            </div>
          </div>
          <span
            class="text-danger text-sm error-text"
            style="
              font-size: 0.65rem !important;
              padding: 2px 4px;
              padding-bottom: 4px;
              display: block;
            "
            v-if="
              errors.first('displayAddress') ||
              clinicData.address.displayAddress ||
              checkDisplayAddress != clinicData.address.displayAddress
            "
            >Enter valid address.</span
          >
        </div>
      </vs-col> -->

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6" >
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('basic.city') &&
              clinicData.address.suburb != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.city') ? true : false"
            name="city"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.city')"
            data-vv-as="City"
            label="City"
            class="w-full"
            v-model="clinicData.address.suburb"
          />
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('basic.state') &&
              clinicData.address.state != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.state') ? true : false"
            name="state"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.state')"
            data-vv-as="State"
            label="State"
            class="w-full"
            v-model="clinicData.address.state"
          />
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6" >
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('basic.postCode') &&
              clinicData.address.postCode != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.postCode') ? true : false"
            name="postCode"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.postCode')"
            data-vv-as="postCode"
            label="Zipcode"
            class="w-full"
            v-model="clinicData.address.postCode"
          />
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6" v-if="region !== 'US'">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('basic.postalAddress') &&
              clinicData.postalAddress != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.postalAddress') ? true : false"
            name="postalAddress"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.postalAddress')"
            data-vv-as="postal address"
            label="Clinic Postal Address (Optional)"
            class="w-full"
            v-model="clinicData.postalAddress"
          />
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-align="left" vs-w="12">
        <div class="w-full m-5">
          <label for>Admin Approval</label>
          <vs-switch
            class="m-2"
            @click="changeAdminApproval(clinicData.hasApprovedByAdmin)"
            v-model="clinicData.hasApprovedByAdmin"
          />
          <div
            v-if="adminApprovalChangeClick"
            style="width: 20px; height: auto; display: flex"
          >
            <span style="margin-right: 5px">Processing</span>

            <img
              src="../../../../public/loader.gif"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-align="left" vs-w="12">
        <div class="w-full m-5">
          <label for>Disable User</label>
          <vs-switch
            @click="changeStatus(clinicData.status)"
            class="m-2"
            color="danger"
            v-model="clinicData.status"
          />
          <div
            v-if="statusChangedClick"
            style="width: 20px; height: auto; display: flex"
          >
            <span style="margin-right: 5px">Processing</span>

            <img
              src="../../../../public/loader.gif"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success-text="sageSuccess"
            :success="sageSuccess ? true : false"
            :danger-text="sageError"
            :danger="sageError ? true : false"
            val-icon-success="done"
            val-icon-danger="error"
            name="sageClientId"
            data-vv-scope="basic"
            data-vv-as="Sage Client ID"
            label="Sage Client ID"
            class="w-full"
            readonly
            v-model="clinicData.sageClientId"
          />
        </div>
      </vs-col>

      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <label>Clinic Payment Terms</label>
            <vs-switch v-model="clinicData.isAccessPaymentTerms" />
          </div>
        </vs-col> -->
    </vs-row>

    <!-- <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-row vs-type="flex" vs-align="left" class="w-full">
          <div class="w-full m-5">
            <h5>Clinic Details</h5>
          </div>
        </vs-row>

        ≈ <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <div :class="{ dateClass: !errors.has('abnCertificate') }">
              <label class="vs-input--label custom-label"
                >ABN Certificate (Please upload your ABN certificate, or a
                screenshot from https://abr.business.gov.au/)</label
              >
              <div style="margin-top: 3px">
                <div class="mb-4" v-if="clinicData.abnCertificate">
                  <div
                    v-if="
                      determineFileType(clinicData.abnCertificate) === 'pdf'
                    "
                    style="margin: auto; width: 50%"
                  >
                    <a :href="clinicData.abnCertificate" target="_blank"
                      >Click here to view file</a
                    >
                  </div>
                  <div v-else style="margin: auto" class="register-img-wrapper">
                    <a :href="clinicData.abnCertificate" target="_blank">
                      <img :src="clinicData.abnCertificate" class="img-fluid" />
                    </a>
                  </div>
                </div>
                <div
                  id="abnCertificateUpload"
                  :class="{ 'mt-3': clinicData.abnCertificate }"
                  style="text-align: center; position: relative"
                >
                  <label
                    id="abnCertificateId"
                    :class="{ 'six-pixel': errors.has('abnCertificate') }"
                    class="
                      image-upload
                      abn-certificate-upload
                      vs-con-loading__container
                    "
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                    "
                  >
                    + Attach Document
                    <input
                      type="file"
                      @change="
                        uploadImage(
                          $event,
                          'abnCertificateUpload',
                          'abn-certificate-upload'
                        )
                      "
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </div>
            </div>
            <span class="text-danger text-sm error-text no-margin-span">
              {{ errors.first("abnCertificate") }}
            </span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <ClinicPhotos
              :clinicPhotosField="clinicData.councilPermits"
              from="create"
              loadingClass="councilPermits"
              label="Please upload any required council permits (Optional)"
              popupTitle="Councli Permits"
              @removeImage="removeImage"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <ClinicPhotos
              :clinicPhotosField="clinicData.poisonsCertificate"
              from="create"
              loadingClass="poisonsCertificate"
              label="Please upload your poisons certificate (For clinics in VIC and WA) (Optional)"
              popupTitle="Poisons Certificate"
              @removeImage="removeImage"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              data-vv-scope="basic"
              :danger="errors.first('basic.clinicSuburb') ? true : false"
              :danger-text="errors.first('basic.clinicSuburb')"
              :success="
                !errors.first('basic.clinicSuburb') &&
                clinicData.address.suburb != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="{ required: isRequired }"
              data-vv-as="clinic suburb"
              name="clinicSuburb"
              label="Clinic Suburb"
              v-model="clinicData.address.suburb"
              class="w-full"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              data-vv-scope="basic"
              :danger-text="errors.first('basic.clinicPostCode')"
              :danger="errors.first('basic.clinicPostCode') ? true : false"
              :success="
                !errors.first('basic.clinicPostCode') &&
                clinicData.address.postCode != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="{ required: isRequired, integer: true }"
              data-vv-as="clinic post code"
              name="clinicPostCode"
              label="Clinic Post Code"
              v-model="clinicData.address.postCode"
              class="w-full"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              data-vv-scope="basic"
              :danger-text="errors.first('basic.clinicState')"
              :danger="errors.first('basic.clinicState') ? true : false"
              :success="
                !errors.first('basic.clinicState') &&
                clinicData.address.state != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="{ required: isRequired }"
              data-vv-as="clinic state"
              name="clinicState"
              label="Clinic State"
              v-model="clinicData.address.state"
              class="w-full"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <div style="margin-top: 13px">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
              >
                Is this a residential address?
              </label>
              <vs-radio
                vs-value="Yes"
                vs-name="radios1"
                style="margin-right: 20px"
                v-model="clinicData.isResidentialAddress"
                >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios1"
                v-model="clinicData.isResidentialAddress"
                >No</vs-radio
              >
              <span
                v-if="validationKeys.showIsResidentialAddressError"
                style="display: block"
                class="text-danger text-sm error-text no-margin-span"
                >Please select one option.</span
              >
            </div>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              data-vv-scope="basic"
              :danger-text="errors.first('basic.clinicAddress1')"
              :danger="errors.first('basic.clinicAddress1') ? true : false"
              :success="
                !errors.first('basic.clinicAddress1') &&
                clinicData.clinicAddress1 != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              data-vv-as="Clinic Address 1"
              name="clinicAddress1"
              label="Clinic Address 1 (Optional)"
              v-model="clinicData.clinicAddress1"
              class="w-full"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('basic.bestContactName') &&
                clinicData.bestContactName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              data-vv-scope="basic"
              :danger="errors.first('basic.bestContactName') ? true : false"
              v-validate="{ required: isRequired }"
              :danger-text="errors.first('basic.bestContactName')"
              name="bestContactName"
              data-vv-as="best contact name"
              label="Best Contact Name"
              class="w-full"
              v-model="clinicData.bestContactName"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('basic.bestContactNumber') &&
                clinicData.bestContactNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.bestContactNumber') ? true : false"
              v-validate="'required'"
              data-vv-scope="basic"
              name="bestContactNumber"
              :danger-text="errors.first('basic.bestContactNumber')"
              data-vv-as="best contact number"
              label="Best Contact Number"
              class="w-full"
              v-model="clinicData.bestContactNumber"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('basic.bestContactEmail') &&
                clinicData.bestContactEmail != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.bestContactEmail') ? true : false"
              v-validate="{ required: isRequired, email: true }"
              data-vv-scope="basic"
              name="bestContactEmail"
              :danger-text="errors.first('basic.bestContactEmail')"
              data-vv-as="best contact email"
              label="Best Contact Email"
              class="w-full"
              v-model="clinicData.bestContactEmail"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('instagram') ? true : false"
              :success="
                !errors.first('instagram') && clinicData.instagram != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              data-vv-as="instagram"
              name="instagram"
              label="Instagram (Optional)"
              v-model="clinicData.instagram"
              class="w-full"
            />
            <span class="text-danger text-sm error-text">{{
              errors.first("instagram")
            }}</span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('facebook') ? true : false"
              :success="!errors.first('facebook') && clinicData.facebook != ''"
              val-icon-success="done"
              val-icon-danger="error"
              data-vv-as="facebook"
              name="facebook"
              label="Facebook (Optional)"
              v-model="clinicData.facebook"
              class="w-full"
            />
            <span class="text-danger text-sm error-text">{{
              errors.first("facebook")
            }}</span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('website') ? true : false"
              :success="!errors.first('website') && clinicData.website != ''"
              val-icon-success="done"
              val-icon-danger="error"
              data-vv-as="website"
              name="website"
              label="Website (Optional)"
              v-model="clinicData.website"
              class="w-full"
            />
            <span class="text-danger text-sm error-text">{{
              errors.first("website")
            }}</span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="{ required: isRequired, email: true }"
              name="invoiceEmail"
              :danger-text="errors.first('basic.invoiceEmail')"
              data-vv-scope="basic"
              data-vv-as="invoice email"
              label="Invoice Email"
              class="w-full"
              v-model="clinicData.invoiceEmail"
              :success="
                !errors.first('basic.invoiceEmail') &&
                clinicData.invoiceEmail != ''
              "
              :danger="errors.first('basic.invoiceEmail') ? true : false"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="2">
          <div class="w-full m-5">
            <label for>Discount Enable</label>
            <vs-switch
              class="m-2"
              @click="changeDiscountEnable(clinicData.isEnableProductDiscount)"
              v-model="clinicData.isEnableProductDiscount"
            />
            <div
              v-if="discountEnableChangeClick"
              style="width: 20px; height: auto; display: flex"
            >
              <span style="margin-right: 5px">Processing</span>

              <img
                src="../../../../public/loader.gif"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label>Clinic Discount</label>
            <vs-select
              data-vv-as="clinicProductDiscountId"
              name="clinicProductDiscountId"
              placeholder="Choose Discount"
              autocomplete
              v-model="clinicData.clinicProductDiscountId"
              class="w-full"
            >
              <vs-select-item :value="null" text="None"> </vs-select-item>
              <vs-select-item
                v-for="(item, index) in discounts"
                :key="index"
                :value="item._id"
                :text="item.name"
              />
            </vs-select>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <div style="margin-top: 13px">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
              >
                Do you have adrenaline and hyalase in the clinic?
              </label>
              <vs-radio
                vs-value="Yes"
                vs-name="radios99"
                style="margin-right: 20px"
                v-model="clinicData.haveAdrenalineAndHyalaseInClinic"
                >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios99"
                v-model="clinicData.haveAdrenalineAndHyalaseInClinic"
                >No</vs-radio
              >
              <span
                v-if="validationKeys.showHaveAdrenalineAndHyalaseInClinicError"
                style="display: block"
                class="text-danger text-sm error-text no-margin-span"
                >Please select one option.</span
              >
            </div>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <div style="margin-top: 13px">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
              >
                Do you intend to secure S4 medication for your clinic through
                Fresh Clinics
              </label>
              <vs-radio
                vs-value="Yes"
                vs-name="radios999"
                style="margin-right: 20px"
                v-model="clinicData.intendToSecureS4Medication"
                >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios999"
                style="margin-right: 20px"
                v-model="clinicData.intendToSecureS4Medication"
                >No</vs-radio
              >
              <vs-radio
                vs-value="Unsure"
                vs-name="radios999"
                v-model="clinicData.intendToSecureS4Medication"
                >Unsure</vs-radio
              >
              <span
                v-if="validationKeys.showIntendToSecureS4MedicationError"
                style="display: block"
                class="text-danger text-sm error-text no-margin-span"
                >Please select one option.</span
              >
            </div>
          </div>
        </vs-col>

        <clinic-opening-hours
          ref="stepTwoRef"
          :weekDaysData="clinicData.clinicOpeningHours"
          from="create"
        />

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <ClinicPhotos
              ref="stepThreeRef"
              :clinicPhotosField="clinicData.clinicFrontPhoto"
              from="create"
              loadingClass="clinicFrontPhoto"
              label="Upload Clinic Front Photos (Please upload a photo of the front of your clinic (from the street))"
              popupTitle="Clinic Front Photos"
              @removeImage="removeImage"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <ClinicPhotos
              ref="step4Ref"
              :clinicPhotosField="clinicData.clinicFloorPhoto"
              from="create"
              loadingClass="clinicFloorPhoto"
              label="Upload Clinic Floor Photos (Please upload photos of the floor of the treatment areas of your clinic)"
              popupTitle="Clinic Floor Photos"
              @removeImage="removeImage"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <ClinicPhotos
              ref="step5Ref"
              :clinicPhotosField="clinicData.clinicTreatmentBedPhoto"
              from="create"
              loadingClass="clinicTreatmentBedPhoto"
              label="Treatment bed (Please upload photos of the treatment beds in your clinic)"
              popupTitle="Clinic Treatment Bed Photos"
              @removeImage="removeImage"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <ClinicPhotos
              ref="step6Ref"
              :clinicPhotosField="clinicData.clinicDrawingUpAreaPhoto"
              from="create"
              loadingClass="clinicDrawingUpAreaPhoto"
              label="Drawing up area (Please upload photos of the drawing up areas in your treatment rooms. Please show gloves and hand-washing facilities, if available)"
              popupTitle="Clinic Drawing Up Area Photos"
              @removeImage="removeImage"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <ClinicPhotos
              ref="step7Ref"
              :clinicPhotosField="clinicData.clinicFridgePhoto"
              from="create"
              loadingClass="clinicFridgePhoto"
              label="Fridge (Please upload photos of the fridge for medication storage, including your temperature monitoring device. Show your lock if available)"
              popupTitle="Clinic Fridge Photos"
              @removeImage="removeImage"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <ClinicPhotos
              ref="step8Ref"
              :clinicPhotosField="clinicData.clinicDrugStoragePhoto"
              from="create"
              loadingClass="clinicDrugStoragePhoto"
              label="Drug Storage (Please upload photos of the cupboard where other medications will be stored. Show the ability to lock the cupboard, if possible.)"
              popupTitle="Clinic Drug Storage Photos"
              @removeImage="removeImage"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <ClinicPhotos
              ref="step9Ref"
              :clinicPhotosField="clinicData.clinicBinsPhoto"
              from="create"
              loadingClass="clinicBinsPhoto"
              label="Bins (Please show photos of your sharps bins and general storage bins.)"
              popupTitle="Clinic Bins Photos"
              @removeImage="removeImage"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <ClinicPhotos
              ref="step10Ref"
              :clinicPhotosField="clinicData.clinicRoomLockPhoto"
              from="create"
              loadingClass="clinicRoomLockPhoto"
              label="Room Lock (Please show photos of the locks on your treatment room, if available.) (Optional)"
              popupTitle="Clinic Room Lock Photos"
              @removeImage="removeImage"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <ClinicPhotos
              ref="step11Ref"
              :clinicPhotosField="clinicData.clinicEpipenAndHyalasePhoto"
              from="create"
              loadingClass="clinicEpipenAndHyalasePhoto"
              label="Emergency Medication (Please upload photos of your epipen (or adrenaline) and hyalase. Please show expiry dates in the images. If you do not have these medications you may purchase them after signing up.) (Optional)"
              popupTitle="Clinic Emergency Medication Photos"
              @removeImage="removeImage"
            />
          </div>
        </vs-col>
      </vs-row> -->
    <!-- <vs-row>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label class="label" name="clinicAssignedSuppliers"
              >Assign Suppliers</label
            >
            <v-select
              multiple
              :closeOnSelect="false"
              label="name"
              danger-text="This field is invalid"
              :options="suppliers"
              value="value"
              :reduce="(option) => option._id"
              v-model="clinicData.clinicAssignedSuppliers"
              data-vv-as="clinicAssignedSuppliers"
              data-vv-scope="basic"
              name="clinicAssignedSuppliers"
              class="w-full vs-con-input"
            >
            </v-select>
            <div
              class="
                con-text-validation
                span-text-validation-danger
                vs-input--text-validation-span
                v-enter-to
              "
              style="height: 19px"
            >
              <span class="span-text-validation">
                {{ errors.first("basic.applicableAreas") }}
              </span>
            </div>
          </div>
        </vs-col>
      </vs-row> -->

    <div id="resetPasswordContainer">
      <vs-card v-if="resetPassword == true">
        <h4 class="mb-2 ml-5 mt-2">Reset Password</h4>
        <form autocomplete="off">
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
          >
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-input
                  :success="
                    !errors.first('resetPassword.newPassword') && password != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('resetPassword.newPassword') ? true : false
                  "
                  name="newPassword"
                  :danger-text="errors.first('resetPassword.newPassword')"
                  label="New Password"
                  class="w-full"
                  v-validate="{
                    required: true,
                    min: 6,
                    regex:
                      /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                  }"
                  data-vv-as="new password"
                  data-vv-scope="resetPassword"
                  ref="password"
                  icon-no-border
                  icon-pack="feather"
                  v-model="password"
                  :icon="passwordIcon"
                  :type="passwordType"
                  @click.native="showHidePassword($event)"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-input
                  label="Confirm Password"
                  :success="
                    !errors.first('resetPassword.confirmPassword') &&
                    confirmPassword != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('resetPassword.confirmPassword') ? true : false
                  "
                  name="confirmPassword"
                  :danger-text="errors.first('resetPassword.confirmPassword')"
                  v-validate="'required|confirmed:password'"
                  data-vv-as="confirm password"
                  data-vv-scope="resetPassword"
                  class="w-full"
                  icon-no-border
                  icon-pack="feather"
                  v-model="confirmPassword"
                  :icon="confirmPasswordIcon"
                  :type="confirmPasswordType"
                  @click.native="showHideConfirmPassword($event)"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-button
                  size="lg"
                  @click="changePassword"
                  icon-pack="feather"
                  class="mr-2"
                  >Reset Password</vs-button
                >
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5" align="right">
                <vs-button
                  @click="resetPassword = false"
                  color="danger"
                  icon-pack="feather"
                  class="mr-2"
                  >Cancel</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </form>
      </vs-card>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import VerifyEmail from "@/views/general-component/VerifyEmail";
import axios from "@/axios";

import ClinicOpeningHours from "./ClinicOpeningHours.vue";
import ClinicPhotos from "./ClinicPhotos";
import { EventBus } from "../../../main";
import vSelect from "vue-select";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import changeOrg from "@/views/components/others/changeOrganisation.vue"

const dict = {
  custom: {
    bestContactNumber: {
      required: "Please enter your best contact number",
    },
    newPassword: {
      required: "Please enter your password",
      min: "Password must be at least 6 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    confirmPassword: {
      required: "Please enter your confirm password",
      confirmed: "The password confirmation does not match",
    },
  },
};

Validator.localize("en", dict);

export default {
  name: "clinicEditDetail",
  inject: ["$validator"],
  props: {
    backRoute: {
      type: String,
      default: "SuperAdminClinic",
    },
    organizationDetailRoute: {
      type: String,
      default: "SuperAdminOrganizationDetails",
    },
  },

  components: {
    VerifyEmail,
    ClinicOpeningHours,
    ClinicPhotos,
    vSelect,
    "change-org": changeOrg,
  },
  data: () => ({
    changeOrgPopup: false,
    organizationDetail: {},
    isRequired: true,
    discounts: [],
    suppliers: [],
    validationKeys: {
      showIsResidentialAddressError: false,
      showIntendToSecureS4MedicationError: false,
      showHaveAdrenalineAndHyalaseInClinicError: false,
    },
    statusChangedClick: false,
    adminApprovalChangeClick: false,
    discountEnableChangeClick: false,
    clinicId: "",
    password: "",
    confirmPassword: "",
    popUpDelete: false,
    clinicData: {
      isProfileCompleted: false,
      clinicAssignedSuppliers: [],
      clinicDiscounts: [],
      firstName: "",
      lastName: "",
      companyName: "",
      clinicName: "",
      abn: "",
      address: {
        displayAddress: "",
        state: "",
        suburb: "",
        postCode: "",
        geometry: { type: "Point", coordinates: [] },
      },
      postalAddress: "",
      bestContactName: "",
      bestContactNumber: "",
      bestContactEmail: "",
      billingEmail: "",
      invoiceEmail: "",
      clinicProductDiscountId: null,
      isAccessPaymentTerms: false,
      isEnableProductDiscount: false,
      status: false,
      hasApprovedByAdmin: false,
      emailVerifiedAt: null,
      contactNumber: "",
      instagram: "",
      facebook: "",
      website: "",
      clinicAddress1: "",
      isResidentialAddress: "",
      haveAdrenalineAndHyalaseInClinic: "",
      intendToSecureS4Medication: "",
      abnCertificate: "",
      councilPermits: [],
      poisonsCertificate: [],
      clinicOpeningHours: {
        mondays: "",
        tuesdays: "",
        wednesdays: "",
        thursdays: "",
        fridays: "",
        saturdays: "",
        sundays: "",
      },
      clinicFrontPhoto: [],
      clinicFloorPhoto: [],
      clinicTreatmentBedPhoto: [],
      clinicDrawingUpAreaPhoto: [],
      clinicFridgePhoto: [],
      clinicDrugStoragePhoto: [],
      clinicBinsPhoto: [],
      clinicRoomLockPhoto: [],
      clinicEpipenAndHyalasePhoto: [],
      removedImages: [],
      sageClientId: "",
    },
    resetPassword: false,
    checkDisplayAddress: "",
    addressData: [],
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
    confirmPasswordType: "password",
    confirmPasswordIcon: "icon icon-eye-off",
    region: process.env.VUE_APP_REGION,
    accessToken: process.env.VUE_APP_MAPBOX_API_KEY,
    sageError:'',
    sageSuccess:'',
  }),
  methods: {
    ...mapActions("clinic", [
      "fetchClinicsDetail",
      "updateClinicDetail",
      "resetClinicPassword",
      "deleteClinic",
      "removeClinicImages",
    ]),
    ...mapActions("discount", ["fetchDiscountList"]),
    ...mapActions("storeProducts", ["fetchSuppliers"]),
    resetPasswordHandler() {
      this.resetPassword = true;
      window.scrollTo(0, document.body.scrollHeight);
    },
    async changePassword() {
      const isValid = await this.$validator.validateAll("resetPassword");
      if (isValid) {
        await this.resetClinicPassword({
          id: this.clinicId,
          data: {
            password: this.password,
            confirmPassword: this.confirmPassword,
          },
        }).then((res) => {
          this.password = "";
          this.confirmPassword = "";
          this.$validator.reset();
          this.$vs.notify({
            title: "Clinic password reset",
            text: "Clinic's password reset Successfully.",
            color: "success",
          });
        });
      }
    },
    openOrganization() {
      this.$router.push({
        name: this.organizationDetailRoute,
        params: { organizationId: this.organizationDetail._id },
      });
    },
    closeOrganization() {
      this.changeOrgPopup = false;
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    uploadImage(event, key = "profileImage", loadingClass = "") {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "abnCertificateUpload") {
            document.getElementById("abnCertificateId").style.borderColor =
              "#cccccc";
            this.clinicData.abnCertificate = res.data.Location;
            this.errors.remove("abnCertificate");
          }

          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    removeImage(imageUrl, selector, loadingClass) {
      // this.$vs.loading({
      //   background: "#fff",
      //   color: "#626262",
      //   container: `#${selector}`,
      //   scale: 0.45,
      //   text: "removing"
      // });

      this.clinicData[loadingClass] = this.clinicData[loadingClass].filter(
        (value) => value.photoUrl != imageUrl
      );

      this.clinicData.removedImages.push(imageUrl);
      // this.$vs.loading.close(`#${selector} > .con-vs-loading`);

      // this
      //   .removeClinicImages(imageUrl)
      //   .then(response => {
      //     this.clinicData[loadingClass] = this.clinicData[loadingClass].filter(
      //       value => value.photoUrl != imageUrl
      //     );
      //     this.$vs.loading.close(`#${selector} > .con-vs-loading`);
      //   })
      //   .catch(error => {
      //     this.$vs.notify({
      //       title: "Removing Failed",
      //       text: "Please try again later.",
      //       iconPack: "feather",
      //       icon: "icon-alert-circle",
      //       color: "danger"
      //     });
      //     this.$vs.loading.close(`#${selector} > .con-vs-loading`);
      //   });
    },
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    showHideConfirmPassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.confirmPasswordType === "password"
          ? (this.confirmPasswordType = "text")
          : (this.confirmPasswordType = "password");
        this.confirmPasswordIcon === "icon icon-eye-off"
          ? (this.confirmPasswordIcon = "icon icon-eye")
          : (this.confirmPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async getClinicDetail(id) {
      await this.fetchClinicsDetail(id)
        .then((res) => {
          const clinic = res.data.data;
          this.organizationDetail = { ...clinic.organizationDetail };
          this.clinicId = clinic._id;
          this.clinicData.firstName = clinic.firstName;
          this.clinicData.lastName = clinic.lastName;
          this.clinicData.companyName = clinic.companyName;
          this.clinicData.clinicName = clinic.clinicName;
          this.clinicData.abn = clinic.abn;
          this.clinicData.streetAddress = clinic.address
            ? clinic.address.displayAddress
            : "";
          this.clinicData.postalAddress = clinic.postalAddress;
          this.clinicData.bestContactName = clinic.bestContactName;
          this.clinicData.bestContactNumber = clinic.bestContactNumber;
          this.clinicData.bestContactEmail = clinic.bestContactEmail;
          this.clinicData.billingEmail = clinic.billingEmail;
          this.clinicData.invoiceEmail = clinic.invoiceEmail;
          this.clinicData.isAccessPaymentTerms =
            typeof clinic.isAccessPaymentTerms !== "undefined"
              ? clinic.isAccessPaymentTerms
              : false;
          this.clinicData.isBYODoctor =
            typeof clinic.isBYODoctor !== "undefined"
              ? clinic.isBYODoctor
              : false;
          this.clinicData.isEnableProductDiscount =
            typeof clinic.isEnableProductDiscount !== "undefined"
              ? clinic.isEnableProductDiscount
              : false;
          this.clinicData.clinicProductDiscountId =
            clinic.clinicProductDiscountId
              ? clinic.clinicProductDiscountId
              : null;
          this.clinicData.clinicAssignedSuppliers =
            clinic.clinicAssignedSuppliers
              ? clinic.clinicAssignedSuppliers
              : [];
          this.clinicData.clinicDiscounts =
            clinic.clinicDiscounts
              ? clinic.clinicDiscounts
              : [];
          this.clinicData.address = clinic.address;
          this.clinicData.status = clinic.status === "Active" ? false : true;
          this.checkDisplayAddress = clinic.address.displayAddress;
          this.clinicData.hasApprovedByAdmin = clinic.hasApprovedByAdmin;
          this.clinicData.emailVerifiedAt = clinic.emailVerifiedAt;
          this.clinicData.email = clinic.email;
          this.clinicData.bestContactNumber = clinic.bestContactNumber
            ? clinic.bestContactNumber
            : "";
          this.clinicData.instagram = clinic.instagram ? clinic.instagram : "";
          this.clinicData.facebook = clinic.facebook ? clinic.facebook : "";
          this.clinicData.website = clinic.website ? clinic.website : "";
          this.clinicData.clinicAddress1 = clinic.clinicAddress1
            ? clinic.clinicAddress1
            : "";
          this.clinicData.isResidentialAddress = clinic.isResidentialAddress
            ? clinic.isResidentialAddress
            : "";
          this.clinicData.haveAdrenalineAndHyalaseInClinic =
            clinic.haveAdrenalineAndHyalaseInClinic
              ? clinic.haveAdrenalineAndHyalaseInClinic
              : "";
          this.clinicData.intendToSecureS4Medication =
            clinic.intendToSecureS4Medication
              ? clinic.intendToSecureS4Medication
              : "";
          this.clinicData.abnCertificate = clinic.abnCertificate
            ? clinic.abnCertificate
            : "";
          this.clinicData.councilPermits = clinic.councilPermits
            ? clinic.councilPermits
            : [];
          this.clinicData.poisonsCertificate = clinic.poisonsCertificate
            ? clinic.poisonsCertificate
            : [];
          this.clinicData.clinicOpeningHours = clinic.clinicOpeningHours
            ? clinic.clinicOpeningHours
            : {
                mondays: "",
                tuesdays: "",
                wednesdays: "",
                thursdays: "",
                fridays: "",
                saturdays: "",
                sundays: "",
              };
          this.clinicData.clinicFrontPhoto = clinic.clinicFrontPhoto
            ? clinic.clinicFrontPhoto
            : [];
          this.clinicData.clinicFloorPhoto = clinic.clinicFloorPhoto
            ? clinic.clinicFloorPhoto
            : [];
          this.clinicData.clinicTreatmentBedPhoto =
            clinic.clinicTreatmentBedPhoto
              ? clinic.clinicTreatmentBedPhoto
              : [];
          this.clinicData.clinicDrawingUpAreaPhoto =
            clinic.clinicDrawingUpAreaPhoto
              ? clinic.clinicDrawingUpAreaPhoto
              : [];
          this.clinicData.clinicFridgePhoto = clinic.clinicFridgePhoto
            ? clinic.clinicFridgePhoto
            : [];
          this.clinicData.clinicDrugStoragePhoto = clinic.clinicDrugStoragePhoto
            ? clinic.clinicDrugStoragePhoto
            : [];
          this.clinicData.clinicBinsPhoto = clinic.clinicBinsPhoto
            ? clinic.clinicBinsPhoto
            : [];
          this.clinicData.clinicRoomLockPhoto = clinic.clinicRoomLockPhoto
            ? clinic.clinicRoomLockPhoto
            : [];
          this.clinicData.clinicEpipenAndHyalasePhoto =
            clinic.clinicEpipenAndHyalasePhoto
              ? clinic.clinicEpipenAndHyalasePhoto
              : [];
          this.clinicData.isProfileCompleted =
            clinic.isProfileCompleted || false;
          this.clinicData.isTrainingClinic = clinic.isTrainingClinic
            ? clinic.isTrainingClinic : false;
          this.clinicData.isStaffClinic = clinic.isStaffClinic
            ? clinic.isStaffClinic : false;
          this.clinicData.isDrugBookEnabled = clinic.isDrugBookEnabled
            ? clinic.isDrugBookEnabled
            : false;
          this.clinicData.isPOSEnabled = clinic.isPOSEnabled
            ? clinic.isPOSEnabled
            : false;
          this.clinicData.isInventoryEnabled = clinic.isInventoryEnabled
            ? clinic.isInventoryEnabled
            : false;
          this.clinicData.isCreditCardFeeExempt = clinic.isCreditCardFeeExempt
            ? clinic.isCreditCardFeeExempt
            : false;
          this.clinicData.isTaxable = clinic.isTaxable
            ? clinic.isTaxable
            : false;
          this.clinicData.isTestClinic = clinic.isTestClinic
            ? clinic.isTestClinic
            : false;
          this.clinicData.showStore = clinic.showStore ? clinic.showStore : false;
           this.clinicData.paymentTypes = clinic.paymentTypes
            ? clinic.paymentTypes
            : [];
          this.clinicData.orderRecipients = clinic.orderRecipients
            ? clinic.orderRecipients.join(",")
            : [];
          this.clinicData.sageClientId = clinic.sageClientId;
          return this.fetchSuppliers();
        })
        .then((suppliers) => {
          this.suppliers = suppliers.data.data;
          this.getSageCustomerInfo();
        });
    },
    async getDiscountList() {
      await this.fetchDiscountList().then((response) => {
        this.discounts = response.data.data;
      });
    },
    onSuccessfullyVerified(verifiedAt) {
      this.clinicData.emailVerifiedAt = verifiedAt;
    },
    async saveData() {
      let isValid = await this.$validator.validateAll("basic");

      if (
        this.$store.state.AppActiveUser.userType !== "superAdmin" &&
        this.$store.state.AppActiveUser.userType !== "admin" &&
        this.$store.state.AppActiveUser.userType !== "franchise" &&
        this.$store.state.AppActiveUser.userType !== "subFranchise"
      ) {
        isValid = await this.$validator.validate();
        isValid = await this.$refs.stepTwoRef.$validator.validate();
        isValid = await this.$refs.stepThreeRef.$validator.validate();
        isValid = await this.$refs.step4Ref.$validator.validate();
        isValid = await this.$refs.step5Ref.$validator.validate();
        isValid = await this.$refs.step6Ref.$validator.validate();
        isValid = await this.$refs.step7Ref.$validator.validate();
        isValid = await this.$refs.step8Ref.$validator.validate();
        isValid = await this.$refs.step9Ref.$validator.validate();
        isValid = await this.$refs.step10Ref.$validator.validate();
        isValid = await this.$refs.step11Ref.$validator.validate();

        if (this.clinicData.isResidentialAddress === "") {
          isValid = false;
          this.validationKeys.showIsResidentialAddressError = true;
        } else {
          this.validationKeys.showIsResidentialAddressError = false;
        }

        if (this.clinicData.intendToSecureS4Medication === "") {
          isValid = false;
          this.validationKeys.showIntendToSecureS4MedicationError = true;
        } else {
          this.validationKeys.showIntendToSecureS4MedicationError = false;
        }

        if (this.clinicData.haveAdrenalineAndHyalaseInClinic === "") {
          isValid = false;
          this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = true;
        } else {
          this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = false;
        }

        if (this.clinicData.abnCertificate == "") {
          this.errors.add({
            field: "abnCertificate",
            msg: "ABN Certificate is required",
          });
          document.getElementById("abnCertificateId").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("abnCertificateId").style.borderColor =
            "#cccccc";
          this.errors.remove("abnCertificate");
        }

        if (this.clinicData.clinicFrontPhoto.length === 0) {
          this.$refs.stepThreeRef.errors.add({
            field: "clinicFrontPhoto",
            msg: "Clinic Front Photo is required",
          });
          document.getElementById("clinicFrontPhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicFrontPhoto").style.borderColor =
            "#cccccc";
          this.$refs.stepThreeRef.errors.remove("clinicFrontPhoto");
        }

        if (this.clinicData.clinicFloorPhoto.length === 0) {
          this.$refs.step4Ref.errors.add({
            field: "clinicFloorPhoto",
            msg: "Clinic Floor Photo is required",
          });
          document.getElementById("clinicFloorPhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicFloorPhoto").style.borderColor =
            "#cccccc";
          this.$refs.step4Ref.errors.remove("clinicFloorPhoto");
        }

        if (this.clinicData.clinicTreatmentBedPhoto.length === 0) {
          this.$refs.step5Ref.errors.add({
            field: "clinicTreatmentBedPhoto",
            msg: "Clinic Treatment Bed Photo is required",
          });
          document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
            "#cccccc";
          this.$refs.step5Ref.errors.remove("clinicTreatmentBedPhoto");
        }

        if (this.clinicData.clinicDrawingUpAreaPhoto.length === 0) {
          this.$refs.step6Ref.errors.add({
            field: "clinicDrawingUpAreaPhoto",
            msg: "Clinic Drawing Up Area Photo is required",
          });
          document.getElementById(
            "clinicDrawingUpAreaPhoto"
          ).style.borderColor = "#ea5455";
          isValid = false;
        } else {
          document.getElementById(
            "clinicDrawingUpAreaPhoto"
          ).style.borderColor = "#cccccc";
          this.$refs.step6Ref.errors.remove("clinicDrawingUpAreaPhoto");
        }

        if (this.clinicData.clinicFridgePhoto.length === 0) {
          this.$refs.step7Ref.errors.add({
            field: "clinicFridgePhoto",
            msg: "Clinic Fridge Photo is required",
          });
          document.getElementById("clinicFridgePhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicFridgePhoto").style.borderColor =
            "#cccccc";
          this.$refs.step7Ref.errors.remove("clinicFridgePhoto");
        }

        if (this.clinicData.clinicDrugStoragePhoto.length === 0) {
          this.$refs.step8Ref.errors.add({
            field: "clinicDrugStoragePhoto",
            msg: "Clinic Drug Storage Photo is required",
          });
          document.getElementById("clinicDrugStoragePhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicDrugStoragePhoto").style.borderColor =
            "#cccccc";
          this.$refs.step8Ref.errors.remove("clinicDrugStoragePhoto");
        }

        if (this.clinicData.clinicBinsPhoto.length === 0) {
          this.$refs.step9Ref.errors.add({
            field: "clinicBinsPhoto",
            msg: "Clinic Bins Photo is required",
          });
          document.getElementById("clinicBinsPhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicBinsPhoto").style.borderColor =
            "#cccccc";
          this.$refs.step9Ref.errors.remove("clinicBinsPhoto");
        }
      }

      // if (this.checkDisplayAddress != this.clinicData.address.displayAddress) {
      //   isValid = false;
      //   this.errors.add({
      //     field: "displayAddress",
      //     msg: "Enter valid address.",
      //   });
      // } else {
      //   this.errors.remove("displayAddress");
      // }

      if (isValid) {
        this.$vs.loading();
        await this.updateClinicDetail({
          id: this.clinicId,
          data: this.clinicData,
        })
          .then((res) => {
            // this.clinicData.emailVerifiedAt = res.data.data.emailVerifiedAt;
            this.$vs.notify({
              title: "Success",
              text: "Clinic updated successfully.",
              color: "success",
            });
            this.statusChangedClick = false;
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.notify({
              title: "Failed",
              text: "Failed to update clinic. Try again!",
              color: "danger",
            });
            this.statusChangedClick = false;

            if (err.status === 422) {
              this.mapServerErrors(this, err, "basic");
            }
            if (err.status === 417) {
              if (err.data.title === "Invalid email") {
                this.errors.add({
                  field: "email",
                  msg: err.data.message,
                  scope: "basic",
                });
              } else if (err.data.title === "Invalid ABN") {
                this.errors.add({
                  field: "abn",
                  msg: err.data.message,
                  scope: "basic",
                });
              }
            }

            this.$vs.loading.close();
          });
      }
    },
    async changeAdminApproval(status) {
      this.adminApprovalChangeClick = true;
      await this.updateClinicDetail({
        id: this.clinicId,
        data: { hasApprovedByAdmin: !status },
      })
        .then((res) => {
          this.$vs.notify({
            title: "Success",
            text: "Admin approval updated successfully.",
            color: "success",
          });
          this.adminApprovalChangeClick = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update admin approval. Try again!",
            color: "danger",
          });
          this.adminApprovalChangeClick = false;
        });
    },
    async changeDiscountEnable(status) {
      this.discountEnableChangeClick = true;
      await this.updateClinicDetail({
        id: this.clinicId,
        data: { isEnableProductDiscount: !status },
      })
        .then((res) => {
          this.$vs.notify({
            title: "Success",
            text: "Discount enable updated successfully.",
            color: "success",
          });
          this.discountEnableChangeClick = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update discount enable. Try again!",
            color: "danger",
          });
          this.discountEnableChangeClick = false;
        });
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
    regionType() {
      const regions = {
        US: "us",
        AU: "au",
        NZ: "nz"
      }
      return regions[this.region]
    },
    async getSageCustomerInfo() {
        try {
            if (!this.clinicData.sageClientId) {
                this.sageSuccess = ''
                this.sageError = ''
                return
            }

            this.$vs.loading();

            const {data} = await axios.get(`/sage/customer/${this.clinicData.sageClientId}`)

            if (data && data.data && data.data.NAME) {
                this.sageSuccess = `Customer ID ${this.clinicData.sageClientId} corresponds to an entity named ${data.data.NAME} with status ${data.data.STATUS} on the Sage platform.`;
                this.sageError = '';
            } else {
                this.sageSuccess = ''
                this.sageError = `The customer with ID ${this.clinicData.sageClientId} does not exist on the Sage platform.`
            }
        } catch (e) {
            this.sageSuccess = ''
            this.sageError = `The customer with ID ${this.clinicData.sageClientId} does not exist on the Sage platform.`
        } finally {
            this.$vs.loading.close();
        }
    },
    async validateSageCustomer() {
        if (this.timeout)
            clearTimeout(this.timeout);

        this.timeout = setTimeout(async () => {
            this.getSageCustomerInfo()
        }, 1000); // delay
    },
  },
  mounted() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: this.regionType() } }
    );
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      this.errors.remove("displayAddress");
      let ac = place.address_components;
      this.addressData = place.address_components;
      this.checkDisplayAddress = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      this.clinicData.address.displayAddress = place.formatted_address;
      this.clinicData.address.geometry.coordinates[0] = lon;
      this.clinicData.address.geometry.coordinates[1] = lat;

      this.clinicData.address.suburb = "";
      this.clinicData.address.state = "";
      this.clinicData.address.postCode = "";
      if (
        place &&
        place.address_components &&
        place.address_components.length > 0
      ) {
        place.address_components.forEach(function (gAdd) {
          if (gAdd.types[0] === "administrative_area_level_2") {
            this.clinicData.address.suburb = gAdd.long_name;
            this.errors.remove("clinicSuburb");
          }
          if (gAdd.types[0] === "administrative_area_level_1") {
            this.clinicData.address.state = gAdd.long_name;
            this.errors.remove("clinicState");
          }
          if (gAdd.types[0] === "postal_code") {
            this.clinicData.address.postCode = gAdd.long_name;
            this.errors.remove("clinicPostCode");
          }
        });
      }
    });
  },
  async created() {
    let clinicId = this.$route.params.clinicId;
    await this.getClinicDetail(clinicId);
    this.isRequired =
      this.$store.state.AppActiveUser.userType === "superAdmin" ||
      this.$store.state.AppActiveUser.userType === "admin" ||
      this.$store.state.AppActiveUser.userType === "franchise" ||
      this.$store.state.AppActiveUser.userType === "subFranchise"
        ? false
        : true;
    await this.getDiscountList();
    mapboxgl.accessToken = this.accessToken;

    var geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      types: 'address',
      countries: this.regionType(),
      placeholder: this.clinicData.address.displayAddress
    });

    geocoder.addTo('#geocoder');
    geocoder.on('result', (e) => {
      this.clinicData.address.suburb = e.result.context.filter(item=>item.id.includes( this.region === 'US' ? 'district' : 'locality'))[0].text
      this.clinicData.address.state = e.result.context.filter(item=>item.id.includes("region"))[0]["text_en-US"]
      this.clinicData.address.postCode = e.result.context.filter(item=>item.id.includes("postcode"))[0].text
      this.clinicData.address.displayAddress = e.result.place_name
      this.clinicData.address.coordinates.longitude = e.result.geometry.coordinates[0];
      this.clinicData.address.coordinates.latitude = e.result.geometry.coordinates[1];
    });
  },
  watch: {
    "clinicData.isResidentialAddress": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showIsResidentialAddressError = false;
      }
    },
    "clinicData.intendToSecureS4Medication": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showIntendToSecureS4MedicationError = false;
      }
    },
    "clinicData.haveAdrenalineAndHyalaseInClinic": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = false;
      }
    },
  },
  beforeDestroy() {
    EventBus.$emit("closePopup");
  },
};
</script>

<style scoped>
.error-text {
  font-size: 0.65rem !important;
  padding: 2px 4px 4px;
  display: block;
  margin-top: -6px;
}

.no-margin-span {
  margin-top: 0 !important;
}
</style>
<style>
  .mapboxgl-ctrl-geocoder {
    width: 100%!important;
    max-width: unset!important;
  }
</style>
