<template>
  <vx-card style="padding-left: 10px" id="settingsTab">
    <div style="float: right">
      <vs-button @click="saveData" icon-pack="feather" class="m-2">Save Details</vs-button>
    </div>

    <!-- Select Organization -->
    <div class="w-full lg:w-1/2 md:w-1/2 my-5">
      <form autocomplete="off">
        <!-- Clinic Details -->
        <div class="mx-5" v-if="role">
          <label class="font-bold">Clinic Details</label>
          <div class="w-full mt-5">
            <vs-select v-validate="'required'" data-vv-as="visible to" name="organizationId"
              placeholder="Choose Organization" autocomplete v-model="clinicData.organizationId" class="w-full"
              val-icon-success="done" val-icon-danger="error" :danger="errors.first('basic.organizationId')"
              :danger-text="errors.first('basic.organizationId')" data-vv-scope="basic" label="Assign Organization"
              :success="
                !errors.first('basic.organizationId') &&
                clinicData.organizationId != ''
              ">
              <vs-select-item v-for="(item, index) in organizations" :key="index" :value="item._id" :text="item.name" />
            </vs-select>
          </div>
          <div class="w-full mt-5">
            <vs-input val-icon-success="done" val-icon-danger="error" v-validate="{ required: true }" name="clinicName"
              :danger="errors.first('basic.clinicName')" :danger-text="errors.first('basic.clinicName')"
              data-vv-scope="basic" data-vv-as="clinicName" label="Clinic Name" class="w-full"
              v-model="clinicData.clinicName" :success="
                !errors.first('basic.clinicName') &&
                clinicData.clinicNamel != ''
              " placeholder="Clinic Name" />
          </div>

          <div class="w-full mt-5">
            <vs-input :danger="errors.first('basic.address') ? true : false"
              :danger-text="errors.first('basic.address')" data-vv-scope="basic" :success="
                !errors.first('basic.address') &&
                clinicData.address.displayAddress != ''
              " ref="autocomplete" val-icon-success="done" val-icon-danger="error" v-validate="'required'"
              data-vv-as="address" name="address" label="Clinic Address" placeholder="Clinic address"
              v-model="clinicData.address.displayAddress" class="w-full" />
          </div>

          <div class="w-full mt-5">
            <vs-input :danger="errors.first('postalAddress') ? true : false" :success="
              !errors.first('postalAddress') && clinicData.postalAddress != ''
            " val-icon-success="done" val-icon-danger="error" data-vv-as="postalAddress" name="postalAddress"
              label="Postal Address" placeholder="Postal Address" v-model="clinicData.postalAddress" class="w-full" />
          </div>

          <div class="w-full mt-5">
            <vs-input :danger="errors.first('basic.bestContactNumber') ? true : false"
              :danger-text="errors.first('basic.bestContactNumber')" :success="
                !errors.first('basic.bestContactNumber') &&
                clinicData.bestContactNumber != ''
              " data-vv-scope="basic" val-icon-success="done" val-icon-danger="error" v-validate="'required'"
              data-vv-as="bestContactNumber" name="bestContactNumber" label="Contact #" placeholder="Contact #"
              v-model="clinicData.bestContactNumber" class="w-full" />
          </div>

          <div class="w-full mt-5">
            <vs-input label="Clinic Aftercare Email" placeholder="Clinic Aftercare Email"
              v-model="clinicData.bestContactEmail" class="w-full" />
          </div>
        </div>

        <!-- clininc settings -->
        <div class="mx-5 mt-10">
          <!-- Clinic Settings -->
          <div class="w-full mt-10">
            <label class="font-bold">Clinic Settings</label>
            <p>
              Some clinic settings are inherited from the organisation this
              clinic is assigned to. These can be changed here if specific
              clinic settings are required.
            </p>

            <div class="w-full mt-5">
              <vs-input :danger="errors.first('basic.invoiceEmail') ? true : false"
                :danger-text="errors.first('basic.invoiceEmail')" data-vv-scope="basic" :success="
                  !errors.first('basic.invoiceEmail') &&
                  clinicData.invoiceEmail != ''
                " val-icon-success="done" val-icon-danger="error" v-validate="'required|email'"
                data-vv-as="invoiceEmail" name="invoiceEmail" label="Monthly Treatment Invoice Email"
                v-model="clinicData.invoiceEmail" class="w-full" />
            </div>
            <div class="w-full mt-5">
              <vs-input :danger="
                errors.first('treatmentConsultationFee') ? true : false
              " :success="
  !errors.first('treatmentConsultationFee') &&
  clinicData.treatmentConsultationFee != ''
" val-icon-success="done" val-icon-danger="error" data-vv-as="treatmentConsultationFee"
                name="treatmentConsultationFee" placeholder="25" label="Treatment consultation Fee(default is $25)"
                v-model="clinicData.treatmentConsultationFee" class="w-full" v-if="role" />
            </div>
            <div class="w-full mt-5">
              <vs-input :danger="
                errors.first('closedLoopConsultationFee') ? true : false
              " :success="
  !errors.first('closedLoopConsultationFee') &&
  clinicData.closedLoopConsultationFee != ''
" val-icon-success="done" val-icon-danger="error" data-vv-as="closedLoopConsultationFee"
                name="closedLoopConsultationFee" label="Closed looped doctors Fee"
                v-model="clinicData.closedLoopConsultationFee" class="w-full" v-if="role" />
            </div>
          </div>
        </div>

        <!-- store invoice settings -->
        <div class="mx-5 mt-10">
          <div class="w-full mt-10">
            <label class="font-bold">Store Invoice Settings</label>
            <p>
              Setup a default email for all order confirmations and updates.
              If this field is empty all order email notifications will be sent to the users email
              who placed the order.
            </p>

            <div class="w-full mt-5">
              <vs-input :danger="errors.first('basic.storeInvoiceEmail') ? true : false"
                :danger-text="errors.first('basic.storeInvoiceEmail')" data-vv-scope="basic" :success="
                  !errors.first('basic.storeInvoiceEmail') &&
                  clinicData.storeInvoiceEmail != ''
                " val-icon-success="done" val-icon-danger="error"
                data-vv-as="storeInvoiceEmail" name="storeInvoiceEmail" label="Store Invoice Email"
                v-model="clinicData.storeInvoiceEmail" class="w-full" />
            </div>
          </div>
        </div>

        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" v-if="role || account === `Org Owner`">
          <vs-row vs-type="flex" vs-align="left" class="w-full">
            <div class="w-full m-5">
              <label class="font-bold">Payment Settings</label>
            </div>
          </vs-row>
          <vs-popup :active.sync="popUpDelete" title="Confirmation">
            <p align="center">
              Are you sure you want to remove payment details from this account?
            </p>
            <br />
            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" vs-mt="6">
              <vs-col vs-type="flex" vs-align="left" vs-w="6">
                <div class="w-full m-5" align="right">
                  <vs-button size="lg" color="primary" @click="removePaymentDetails(deleteParams)">Yes, Remove
                  </vs-button>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-align="left" vs-w="6">
                <div class="w-full m-5">
                  <vs-button size="lg" color="danger" @click="popUpDelete = false">Cancel</vs-button>
                </div>
              </vs-col>
            </vs-row>
          </vs-popup>

          <vs-popup :active.sync="popUpSetDefault" title="Confirmation">
            <p align="center">
              Are you sure you want to set payment details from this account as
              default?
            </p>
            <br />
            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" vs-mt="6">
              <vs-col vs-type="flex" vs-align="left" vs-w="6">
                <div class="w-full m-5" align="right">
                  <vs-button size="lg" color="primary" @click="setDefaultPayment()">Yes</vs-button>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-align="left" vs-w="6">
                <div class="w-full m-5">
                  <vs-button size="lg" color="danger" @click="popUpDelete = false">Cancel</vs-button>
                </div>
              </vs-col>
            </vs-row>
          </vs-popup>

          <ul vs-xs="12" vs-sm="6" vs-lg="6">
            <!-- <li v-if="bankDetails">
              <vx-card class="m-2 relative">
                <div class="absolute top-0 right-0 mt-5 mr-5">
                  <vs-dropdown>
                    <a class="a-icon" href="#">
                      <more-horizontal-icon
                        size="1.5x"
                        class="custom-class"
                      ></more-horizontal-icon>
                    </a>

                    <vs-dropdown-menu>
                      <vs-dropdown-item
                        v-if="!bankDetails.isDefault"
                        @click="setBankAsDefault(bankDetails._id)"
                      >
                        Set as default
                      </vs-dropdown-item>
                      <vs-dropdown-item
                        @click="removeBankAsDefault(bankDetails._id)"
                      >
                        Remove
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
                <div
                  v-if="bankDetails.isDefault"
                  class="absolute top-0 font-bold m-5"
                  style="color: #0e4333"
                >
                  Default
                </div>
                <div class="flex flex-row p-4 px-6">
                  <div class="account-payment-setting">
                    <p>{{ bankDetails.accountHolderName }}</p>
                    <p>{{ bankDetails.accountHolderEmail }}</p>
                    <p>{{ bankDetails.bsb }}/{{ bankDetails.accountNumber }}</p>
                  </div>
                </div>
              </vx-card>
            </li> -->
            <li v-for="(card, index) in paymentDetailsList" :key="index">
              <vx-card class="m-2 w-full relative">
                <div v-if="!card.isPaymentTerm" class="absolute top-0 right-0 mt-5 mr-5">
                  <vs-dropdown>
                    <a class="a-icon" href="#">
                      <vs-icon class="" icon="more"></vs-icon>
                    </a>

                    <vs-dropdown-menu>
                      <vs-dropdown-item v-if="!card.isDefault" @click="setCardAsDefault(card)">
                        Set as default
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="removeCardAsDefault(card)" v-if="isRemoveEnabled">
                        Remove
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
                <div v-if="card.isDefault" class="absolute top-0 font-bold m-5" style="color: #0e4333">
                  Default
                </div>
                <div class="flex flex-row p-4 px-6" v-if="card.type === 'CreditCard'">
                  <div>
                    <p>{{ card.brand }}</p>
                    <p>XXXX XXXX XXXX {{ card.last4 }}</p>
                    <p>
                      {{ card.exp_month }} /
                      {{ card.exp_year }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row p-4 px-6" v-if="card.type === 'BankAccount'">
                  <div>
                    <p>{{ card.accountHolderName }}</p>
                    <p>{{ card.accountHolderEmail }}</p>
                    <p>{{ card.bsb }}/{{ card.accountNumber }}</p>
                  </div>
                </div>
                <div v-if="card.isPaymentTerm" class="flex flex-row p-2 px-4">
                  <div>
                    <p class="font-medium">Payment Terms</p>
                    <p>{{ card.text }}</p>
                    <p class="text-sm">You will receive an email with an invoice</p>
                  </div>
                </div>
              </vx-card>
            </li>
            <li v-if="!bankDetails && paymentDetailsList.length === 0">
              <div class="ml-3 w-full flex items-center justify-center">
                <h5>No payment details setup</h5>
              </div>
            </li>
          </ul>
        </vs-row>
        <div v-if="isCCorDebitEnabled" class="
            flex flex-row
            bg-primary
            rounded-lg
            text-white
            cursor-pointer
            p-2
            ml-4
            mt-10
          " style="width: 230px"  :class="{ disabled: !canAddPayment}" @click="showAddPayment = !showAddPayment">
          <vs-popup class="holamundo" title="Add payment method" :active.sync="showAddPayment">
            <vs-tabs>
              <vs-tab label="Credit Card" @click="paymentType = 'CreditCard'">
                <stripe-elements ref="stripeElement" v-bind:title="saveCreditCard" :pk="stripePublishableKey"
                  :amount="totalPrice" locale="en" @token="tokenCreated" @loading="loading = $event">
                </stripe-elements>
                <vs-button @click="submit">Save</vs-button>
              </vs-tab>
              <vs-tab label="Bank Account" @click="paymentType = 'ClinicBankAccount'">
                <BankSetup :totalPrice="totalPrice" :financialDetails="financialDetails"
                  :isNewClinicBankAccount="isNewClinicBankAccount" :shippingDetails="shippingDetails" :paymentEmail="clinicData.paymentEmail"
                  :from-list="true"
                  :paymentType="'ClinicBankAccount'" :repayment="true" :buttonTitle="'Save'" :clinicId="clinicId"
                  @onClinicBankPaymentSuccess="clinicBankPaymentSuccess"></BankSetup>
              </vs-tab>
            </vs-tabs>
          </vs-popup>
          <plus-icon size="1.5x" class="custom-class"></plus-icon>
          <div class="pl-4 pt-1">Add Payment Method</div>
        </div>

        <div class="mx-5 mt-5 mb-10" v-if="role">
          <label class="font-bold">Is BYO Doctor</label>
          <div class="flex" v-if="role">
            <div class="mt-5">
              <label>Is BYO Doctor</label>
              <vs-switch v-model="clinicData.isBYODoctor" />
            </div>
          </div>
        </div>
        <div class="mx-5 mt-5 mb-10">
          <label class="font-bold">Test Clinic Settings</label>
          <div class="flex" >
            <div class="mt-5">
              <label>Test Clinic</label>
              <vs-switch v-model="clinicData.isTestClinic" />
            </div>
          </div>
        </div>
        <!-- Drug book -->
        <div class="mx-5 mt-10" v-if="role">
          <label class="font-bold">Drug Book Settings</label>
          <div class="flex">
            <div class="mt-5">
              <label>Enable Drug Book</label>
              <vs-switch v-model="clinicData.isDrugBookEnabled" :disabled="!organizationDrugbookOn" />
            </div>
          </div>
        </div>
        <!-- POS -->
        <div class="mx-5 mt-10 mb-10" v-if="role">
          <label class="font-bold">POS Settings</label>
          <div class="flex" >
            <div class="mt-5">
              <label>Enable POS</label>
              <vs-switch v-model="clinicData.isPOSEnabled" />
            </div>
          </div>
        </div>
        <!-- Inventory -->
        <div class="mx-5 mt-10 mb-10" v-if="role">
          <label class="font-bold">Inventory Settings</label>
          <div class="flex">
            <div class="mt-5">
              <label>Enable Inventory</label>
              <vs-switch v-model="clinicData.isInventoryEnabled" :disabled="!organizationInventoryOn" />
            </div>
          </div>
        </div>
        <!-- CC fee -->
        <div class="mx-5 mt-10 mb-10" v-if="role">
          <label class="font-bold">Credit Card</label>
          <div class="flex" >
            <div class="mt-5">
              <label>Enable Credit Card Fee Exemption</label>
              <vs-switch v-model="clinicData.isCreditCardFeeExempt" />
            </div>
          </div>
        </div>

        <div class="mx-5 mt-5 mb-10" v-if="role">
          <label class="font-bold">Training Clinic Settings</label>
          <div class="flex" >
            <div class="mt-5">
              <label>Training clinic</label>
              <vs-switch v-model="clinicData.isTrainingClinic" />
            </div>
          </div>
        </div>

        <div class="mx-5 mt-5 mb-10" v-if="role">
          <label class="font-bold">Staff Clinic Settings</label>
          <div class="flex" >
            <div class="mt-5">
              <label>Staff clinic</label>
              <vs-switch v-model="clinicData.isStaffClinic" />
            </div>
          </div>
        </div>

        <!-- Ecommerce Settings -->
        <div class="mx-5 mt-10" >
          <label class="font-bold">Ecommerce Settings</label>
          <div class="flex" v-if="role">
            <div class="mt-5">
              <label>Access the store</label>
              <vs-switch v-model="clinicData.showStore" />
            </div>
          </div>

          <div class="mt-5" v-if="clinicData.showStore || !role">
            <label>Clinic Hours of Operation</label>
            <div class="w-full mt-5 flex justify-between">
              <div class="w-1/5 flex items-center">
                <label>Monday</label>
              </div>
              <div class="w-4/5">
                <vs-input :danger="errors.first('clinicOpeningHours') ? true : false" :success="
                  !errors.first('clinicOpeningHours') &&
                  clinicData.clinicOpeningHours != ''
                " val-icon-success="done" val-icon-danger="error" data-vv-as="clinicOpeningHours"
                  name="clinicOpeningHours" placeholder="9-5 or closed" v-model="clinicData.clinicOpeningHours.mondays"
                  class="w-full" />
              </div>
            </div>
            <!-- <vs-row
              vs-align="flex-start"
              vs-type="flex"
              vs-justify="left"
              vs-w="12"
            >
              <vs-row vs-type="flex" vs-align="left" class="w-full">
                <div class="w-full m-5">
                  <h5>Ecommerce Settings</h5>
                </div>
              </vs-row>

              <vs-row vs-type="flex" vs-align="left" class="w-full">
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-xs="12"
                  vs-sm="6"
                  vs-lg="6"
                >
                  <div class="w-full m-5">
                    <label>Access the store</label>
                    <vs-switch v-model="clinicData.showStore" />
                  </div>
                </vs-col>
              </vs-row>

              <vs-row vs-type="flex" vs-align="left" class="w-full">
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-xs="12"
                  vs-sm="6"
                  vs-lg="6"
                >
                  <div class="w-full m-5">
                    <label>Clinic Payment Terms</label>
                    <vs-switch v-model="clinicData.isAccessPaymentTerms" />
                  </div>
                </vs-col>
              </vs-row>

              <vs-row vs-type="flex" vs-align="left" class="w-full">
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-xs="12"
                  vs-sm="6"
                  vs-lg="6"
                >
                  <div class="w-full m-5">
                    <label class="label" name="clinicAssignedSuppliers"
                      >Assign Suppliers</label
                    >
                    <v-select
                      multiple
                      :closeOnSelect="false"
                      label="name"
                      danger-text="This field is invalid"
                      :options="suppliers"
                      value="value"
                      :reduce="(option) => option._id"
                      v-model="clinicData.clinicAssignedSuppliers"
                      data-vv-as="clinicAssignedSuppliers"
                      data-vv-scope="basic"
                      name="clinicAssignedSuppliers"
                      class="w-full vs-con-input"
                    >
                    </v-select>
                    <div
                      class="
                        con-text-validation
                        span-text-validation-danger
                        vs-input--text-validation-span
                        v-enter-to
                      "
                      style="height: 19px"
                    >
                      <span class="span-text-validation">
                        {{ errors.first("basic.applicableAreas") }}
                      </span>
                    </div>
                  </div>
                </vs-col>
              </vs-row>

              <vs-row vs-type="flex" vs-align="left" class="w-full">
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-xs="12"
                  vs-sm="6"
                  vs-lg="6"
                >
                  <div class="w-full m-5">
                    <label>Clinic Discount</label>
                    <vs-select
                      data-vv-as="clinicDiscounts"
                      name="clinicDiscounts"
                      placeholder="Choose Discount"
                      autocomplete
                      v-model="clinicData.clinicDiscounts"
                      class="w-full"
                    >
                      <vs-select-item :value="null" text="None">
                      </vs-select-item>
                      <vs-select-item
                        v-for="(item, index) in discounts"
                        :key="index"
                        :value="item._id"
                        :text="item.name"
                      />
                    </vs-select>
                  </div>
                </vs-col>
              </vs-row>
              <vs-row vs-type="flex" vs-align="left" class="w-full">
                <clinic-opening-hours
                  ref="stepTwoRef"
                  :weekDaysData="clinicData.clinicOpeningHours"
                  from="create"
                />
              </vs-row>
            </vs-row> -->
            <div class="w-full mt-5 flex justify-between">
              <div class="w-1/5 flex items-center">
                <label>Tuesday</label>
              </div>
              <div class="w-4/5">
                <vs-input :danger="errors.first('clinicOpeningHours') ? true : false" :success="
                  !errors.first('clinicOpeningHours') &&
                  clinicData.clinicOpeningHours != ''
                " val-icon-success="done" val-icon-danger="error" data-vv-as="clinicOpeningHours"
                  name="clinicOpeningHours" placeholder="9-5 or closed" v-model="clinicData.clinicOpeningHours.tuesdays"
                  class="w-full" />
              </div>
            </div>

            <div class="w-full mt-5 flex justify-between">
              <div class="w-1/5 flex items-center">
                <label>Wednesday</label>
              </div>
              <div class="w-4/5">
                <vs-input :danger="errors.first('clinicOpeningHours') ? true : false" :success="
                  !errors.first('clinicOpeningHours') &&
                  clinicData.clinicOpeningHours != ''
                " val-icon-success="done" val-icon-danger="error" data-vv-as="clinicOpeningHours"
                  name="clinicOpeningHours" placeholder="9-5 or closed"
                  v-model="clinicData.clinicOpeningHours.wednesdays" class="w-full" />
              </div>
            </div>

            <div class="w-full mt-5 flex justify-between">
              <div class="w-1/5 flex items-center">
                <label>Thursday</label>
              </div>
              <div class="w-4/5">
                <vs-input :danger="errors.first('clinicOpeningHours') ? true : false" :success="
                  !errors.first('clinicOpeningHours') &&
                  clinicData.clinicOpeningHours != ''
                " val-icon-success="done" val-icon-danger="error" data-vv-as="clinicOpeningHours"
                  name="clinicOpeningHours" placeholder="9-5 or closed"
                  v-model="clinicData.clinicOpeningHours.thursdays" class="w-full" />
              </div>
            </div>

            <div class="w-full mt-5 flex justify-between">
              <div class="w-1/5 flex items-center">
                <label>Friday</label>
              </div>
              <div class="w-4/5">
                <vs-input :danger="errors.first('clinicOpeningHours') ? true : false" :success="
                  !errors.first('clinicOpeningHours') &&
                  clinicData.clinicOpeningHours != ''
                " val-icon-success="done" val-icon-danger="error" data-vv-as="clinicOpeningHours"
                  name="clinicOpeningHours" placeholder="9-5 or closed" v-model="clinicData.clinicOpeningHours.fridays"
                  class="w-full" />
              </div>
            </div>

            <div class="w-full mt-5 flex justify-between">
              <div class="w-1/5 flex items-center">
                <label>Saturday</label>
              </div>
              <div class="w-4/5">
                <vs-input :danger="errors.first('clinicOpeningHours') ? true : false" :success="
                  !errors.first('clinicOpeningHours') &&
                  clinicData.clinicOpeningHours != ''
                " val-icon-success="done" val-icon-danger="error" data-vv-as="clinicOpeningHours"
                  name="clinicOpeningHours" placeholder="9-5 or closed"
                  v-model="clinicData.clinicOpeningHours.saturdays" class="w-full" />
              </div>
            </div>

            <div class="w-full mt-5 flex justify-between">
              <div class="w-1/5 flex items-center">
                <label>Sunday</label>
              </div>
              <div class="w-4/5">
                <vs-input :danger="errors.first('clinicOpeningHours') ? true : false" :success="
                  !errors.first('clinicOpeningHours') &&
                  clinicData.clinicOpeningHours != ''
                " val-icon-success="done" val-icon-danger="error" data-vv-as="clinicOpeningHours"
                  name="clinicOpeningHours" placeholder="9-5 or closed" v-model="clinicData.clinicOpeningHours.sundays"
                  class="w-full" />
              </div>
            </div>

            <!--
            <clinic-opening-hours
              ref="stepTwoRef"
              :weekDaysData="clinicData.clinicOpeningHours"
              :validate="clinicData.showStore"
              from="create"
            />
          -->
          </div>
          <div class="mt-5" v-if="clinicData.showStore">
            <div class="mt-10" v-if="role">
              <vs-checkbox v-model="clinicData.isDoctorStore">
                Apply doctor store pricing
              </vs-checkbox>
            </div>
            <div class="w-full mt-10">
              <vs-input :danger="errors.first('basic.orderRecipients') ? true : false"
                :danger-text="errors.first('basic.orderRecipients')" :success="
                  !errors.first('basic.orderRecipients') &&
                  clinicData.orderRecipients != ''
                " data-vv-scope="basic" val-icon-success="done" val-icon-danger="error" data-vv-as="orderRecipients"
                name="orderRecipients" placeholder="Store receipts & confirmation emails"
                label="Store receipts & confirmation emails" v-model="clinicData.orderRecipients" class="w-full" />
            </div>

            <div class="w-full mt-5" v-if="role">
              <label>Payment Types</label>
              <vs-select :danger="errors.first('basic.paymentTypes') ? true : false"
                :danger-text="errors.first('basic.paymentTypes')" :success="
                  !errors.first('basic.paymentTypes') &&
                  clinicData.paymentTypes != ''
                " data-vv-scope="basic" val-icon-success="done" val-icon-danger="error" data-vv-as="paymentTypes"
                name="paymentTypes" v-model="clinicData.paymentTypes" class="w-full" multiple v-validate="`required`"
                autocomplete>
                <vs-select-item v-for="(item, index) in paymentTypesOptions" :key="index" :value="item.value"
                  :text="item.text" />
              </vs-select>
            </div>

            <div class="w-full mt-5" v-if="role">
              <label>Suppliers</label>
              <vs-select :danger="
                errors.first('basic.clinicAssignedSuppliers') ? true : false
              " :danger-text="errors.first('basic.clinicAssignedSuppliers')" :success="
  !errors.first('basic.clinicAssignedSuppliers') &&
  clinicData.clinicAssignedSuppliers != ''
" data-vv-scope="basic" val-icon-success="done" val-icon-danger="error"
                data-vv-as="clinicAssignedSuppliers" name="clinicAssignedSuppliers"
                v-model="clinicData.clinicAssignedSuppliers" class="w-full" multiple v-validate="'required'"
                autocomplete>
                <vs-select-item v-for="(item, index) in suppliers" :key="index" :value="item._id" :text="item.name" />
              </vs-select>
            </div>

            <div class="w-full mt-5" v-if="role">
              <label>Clinic Discount</label>
              <vs-select :danger="errors.first('basic.clinicDiscounts') ? true : false"
                :danger-text="errors.first('basic.clinicDiscounts')" :success="
                  !errors.first('basic.clinicDiscounts') &&
                  clinicData.paymentTypes != ''
                " data-vv-scope="basic" val-icon-success="done" val-icon-danger="error" data-vv-as="clinicDiscounts"
                name="clinicDiscounts" v-model="clinicData.clinicDiscounts" class="w-full" multiple autocomplete>
                <vs-select-item v-for="(item, index) in discounts" :key="index" :value="item._id" :text="item.name" />
              </vs-select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import { MoreHorizontalIcon } from "vue-feather-icons";
import VerifyEmail from "@/views/general-component/VerifyEmail";
import axios from "@/axios";
import { uniq } from "lodash";

import ClinicOpeningHours from "./ClinicOpeningHours.vue";
import ClinicPhotos from "./ClinicPhotos";
import { EventBus } from "../../../main";
import vSelect from "vue-select";
import BankSetup from "../order-center/BankSetup";
import StripeElements from "../order-center/StripeElements";
import { PlusIcon } from "vue-feather-icons";

const dict = {
  custom: {
    bestContactNumber: {
      required: "Please enter your best contact number",
    },
    newPassword: {
      required: "Please enter your password",
      min: "Password must be at least 6 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    confirmPassword: {
      required: "Please enter your confirm password",
      confirmed: "The password confirmation does not match",
    }
  },
};

Validator.localize("en", dict);

export default {
  name: "clinicEditDetail",
  inject: ["$validator"],
  props: {
    backRoute: {
      type: String,
      default: "SuperAdminClinic",
    },
  },

  components: {
    VerifyEmail,
    ClinicOpeningHours,
    ClinicPhotos,
    vSelect,
    MoreHorizontalIcon,
    BankSetup,
    StripeElements,
    PlusIcon,
  },
  data: () => ({
    stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    saveCreditCard: true,
    isNewClinicBankAccount: false,
    financialDetails: null,
    paymentType: null,
    shippingDetails: null,
    totalPrice: 0,
    isRequired: true,
    discounts: [],
    suppliers: [],
    deleteParams: {
      isCard: null,
      _id: null,
      cardId: null,
    },
    defaultPaymentParams: {
      userId: null,
      paymentMethod: null,
      cardId: null,
    },
    validationKeys: {
      showIsResidentialAddressError: false,
      showIntendToSecureS4MedicationError: false,
      showHaveAdrenalineAndHyalaseInClinicError: false,
    },
    statusChangedClick: false,
    adminApprovalChangeClick: false,
    discountEnableChangeClick: false,
    clinicId: "",
    password: "",
    confirmPassword: "",
    popUpDelete: false,
    popUpSetDefault: false,
    defaultPaymentMethod: "",
    paymentDetailsList: [],
    bankDetails: null,
    clinicData: {
      invoiceEmail: "",
      storeInvoiceEmail: "",
      showStore: true,
      orderRecipients: "",
      isDoctorStore: false,
      isProfileCompleted: false,
      clinicAssignedSuppliers: [],
      firstName: "",
      lastName: "",
      companyName: "",
      clinicName: "",
      treatmentConsultationFee: null,
      closedLoopConsultationFee: null,
      abn: "",
      address: {
        displayAddress: "",
        state: "",
        suburb: "",
        postCode: "",
        geometry: { type: "Point", coordinates: [] },
      },
      postalAddress: "",
      bestContactName: "",
      bestContactNumber: "",
      bestContactEmail: "",
      billingEmail: "",
      clinicDiscounts: null,
      isAccessPaymentTerms: false,
      isEnableProductDiscount: false,
      status: false,
      hasApprovedByAdmin: false,
      emailVerifiedAt: null,
      email: "",
      contactNumber: "",
      instagram: "",
      facebook: "",
      website: "",
      clinicAddress1: "",
      isResidentialAddress: "",
      haveAdrenalineAndHyalaseInClinic: "",
      intendToSecureS4Medication: "",
      abnCertificate: "",
      councilPermits: [],
      poisonsCertificate: [],
      clinicOpeningHours: {
        mondays: "",
        tuesdays: "",
        wednesdays: "",
        thursdays: "",
        fridays: "",
        saturdays: "",
        sundays: "",
      },
      clinicFrontPhoto: [],
      clinicFloorPhoto: [],
      clinicTreatmentBedPhoto: [],
      clinicDrawingUpAreaPhoto: [],
      clinicFridgePhoto: [],
      clinicDrugStoragePhoto: [],
      clinicBinsPhoto: [],
      clinicRoomLockPhoto: [],
      clinicEpipenAndHyalasePhoto: [],
      removedImages: [],
      isTestClinic: false,
      isDrugBookEnabled: false,
      isTrainingClinic: false,
      isStaffClinic: false,
      isTaxable: false,
      isPOSEnabled: false,
      isInventoryEnabled: false,
      isCreditCardFeeExempt: false,
      paymentEmail: '',
      isBYODoctor: false,
    },
    resetPassword: false,
    checkDisplayAddress: "",
    addressData: [],
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
    confirmPasswordType: "password",
    confirmPasswordIcon: "icon icon-eye-off",
    isDoctorStore: false,
    paymentTypesOptions: [],
    organizations: [],
    organizationId: 0,
    role: false,
    account: false,
    showAddPayment: false,
    organizationDrugbookOn: false,
    organizationInventoryOn: false,
    loading: false,
    canAddPayment: false
  }),
  methods: {
    ...mapActions("admin", ["getSettingsByKey"]),
    ...mapActions("general", [
      "fetchAccountPaymentDetailsList",
      "removeUserBankDetails",
      "removeCreditCardDetails",
      "setDefaultPaymentMethod",
    ]),
    ...mapActions("clinic", [
      "fetchClinicsDetail",
      "updateClinicDetail",
      "resetClinicPassword",
      "deleteClinic",
      "removeClinicImages",
      "addNewPaymentMethod",
    ]),
    ...mapActions("organization", [
      "fetchOrganizations",
      "createOrganizationClinic",
      "getAllSupplierList",
      "getAllDiscountList",
      "fetchOrganizationAssignList",
      "getInstantSupplierList"
    ]),
    ...mapActions("discount", ["fetchDiscountList"]),
    ...mapActions("storeProducts", ["fetchSuppliers"]),
    clinicBankPaymentSuccess(val) {
      this.$vs.loading.close();
      this.showAddPayment = false;
      this.getPaymentDetails();
    },
    submit() {
      this.$refs.stripeElement.submit();
      this.paymentType = "CreditCard";
    },
    tokenCreated(token) {
      this.addNewPaymentMethod({
        token,
        clinicId: this.clinicId,
        intentId: null,
      })
        .then((res) => {
          this.$vs.loading.close();
          this.showAddPayment = false;
          this.getPaymentDetails();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        });
      // this.retryOrder({
      //   source: token.id,
      //   amount: this.totalPrice, // the amount you want to charge the customer in cents. $100 is 1000 (it is strongly recommended you use a product id and quantity and get calculate this on the backend to avoid people manipulating the cost)
      // });
    },
    async getOrganizationList(id) {
      this.fetchOrganizationAssignList({ id: id }).then((res) => {
        this.organizations = res.data.data;
      });
      this.serverResponded = true;
    },

    resetPasswordHandler() {
      this.resetPassword = true;
      window.scrollTo(0, document.body.scrollHeight);
    },
    async changePassword() {
      const isValid = await this.$validator.validateAll("resetPassword");
      if (isValid) {
        await this.resetClinicPassword({
          id: this.clinicId,
          data: {
            password: this.password,
            confirmPassword: this.confirmPassword,
          },
        }).then((res) => {
          this.password = "";
          this.confirmPassword = "";
          this.$validator.reset();
          this.$vs.notify({
            title: "Clinic password reset",
            text: "Clinic's password reset Successfully.",
            color: "success",
          });
        });
      }
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    uploadImage(event, key = "profileImage", loadingClass = "") {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "abnCertificateUpload") {
            document.getElementById("abnCertificateId").style.borderColor =
              "#cccccc";
            this.clinicData.abnCertificate = res.data.Location;
            this.errors.remove("abnCertificate");
          }

          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    setBankAsDefault(id) {
      this.defaultPaymentParams.userId = this.$route.params.clinicId;
      this.defaultPaymentParams.paymentMethod = "BankAccount";
      this.popUpSetDefault = true;
    },
    removeBankAsDefault(id) {
      this.deleteParams.isCard = false;
      this.deleteParams._id = this.$route.params.clinicId;
      this.popUpDelete = true;
    },
    setCardAsDefault(card) {
      this.defaultPaymentMethod = {
        ...card,
        userId: this.$route.params.clinicId,
      };
      // this.defaultPaymentParams.paymentMethod = "CreditCard";
      // this.defaultPaymentParams.cardId = id;
      this.popUpSetDefault = true;
    },
    removeCardAsDefault(card) {
      this.deleteParams = card;
      this.popUpDelete = true;
    },
    removeImage(imageUrl, selector, loadingClass) {
      // this.$vs.loading({
      //   background: "#fff",
      //   color: "#626262",
      //   container: `#${selector}`,
      //   scale: 0.45,
      //   text: "removing"
      // });

      this.clinicData[loadingClass] = this.clinicData[loadingClass].filter(
        (value) => value.photoUrl != imageUrl
      );

      this.clinicData.removedImages.push(imageUrl);
      // this.$vs.loading.close(`#${selector} > .con-vs-loading`);

      // this
      //   .removeClinicImages(imageUrl)
      //   .then(response => {
      //     this.clinicData[loadingClass] = this.clinicData[loadingClass].filter(
      //       value => value.photoUrl != imageUrl
      //     );
      //     this.$vs.loading.close(`#${selector} > .con-vs-loading`);
      //   })
      //   .catch(error => {
      //     this.$vs.notify({
      //       title: "Removing Failed",
      //       text: "Please try again later.",
      //       iconPack: "feather",
      //       icon: "icon-alert-circle",
      //       color: "danger"
      //     });
      //     this.$vs.loading.close(`#${selector} > .con-vs-loading`);
      //   });
    },
    showHidePassword(event) {
      if (
        event.target.className ===
        "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
        "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    showHideConfirmPassword(event) {
      if (
        event.target.className ===
        "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
        "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.confirmPasswordType === "password"
          ? (this.confirmPasswordType = "text")
          : (this.confirmPasswordType = "password");
        this.confirmPasswordIcon === "icon icon-eye-off"
          ? (this.confirmPasswordIcon = "icon icon-eye")
          : (this.confirmPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    fetchInstantSuppliers() {
      this.getInstantSupplierList().then(res => {
        const instant = res.map(({ _id: id }) => id);
        this.clinicData.clinicAssignedSuppliers = uniq([...this.clinicData.clinicAssignedSuppliers, ...instant])
      }).catch(err => {
        console.log(err, 'instant error')
      })
    },
    async getClinicDetail(id) {
      await this.fetchClinicsDetail(id)
        .then((res) => {
          const clinic = res.data.data;
          this.organizationInventoryOn = clinic.organizationDetail.isInventoryEnabled ? clinic.organizationDetail.isInventoryEnabled : false;
          this.organizationDrugbookOn = clinic.organizationDetail.isDrugbookEnabled ? clinic.organizationDetail.isDrugbookEnabled : false;
          // this.clinicId = clinic._id;
          this.clinicData.firstName = clinic.firstName;
          this.clinicData.lastName = clinic.lastName;
          this.clinicData.companyName = clinic.companyName;
          this.clinicData.clinicName = clinic.clinicName;
          this.clinicData.abn = clinic.abn;
          this.clinicData.closedLoopConsultationFee = clinic.closedLoopConsultationFee;
          this.clinicData.treatmentConsultationFee =
            clinic.treatmentConsultationFee
              ? clinic.treatmentConsultationFee
              : clinic.organizationDetail.consultationFee;

          this.clinicData.streetAddress = clinic.address
            ? clinic.address.displayAddress
            : "";
          this.defaultPaymentMethod = clinic.defaultPaymentMethod;
          this.clinicData.postalAddress = clinic.postalAddress;
          this.clinicData.bestContactName = clinic.bestContactName;
          this.clinicData.bestContactNumber = clinic.bestContactNumber;
          this.clinicData.bestContactEmail = clinic.bestContactEmail;
          this.clinicData.billingEmail = clinic.billingEmail;
          this.clinicData.isAccessPaymentTerms =
            typeof clinic.isAccessPaymentTerms !== "undefined"
              ? clinic.isAccessPaymentTerms
              : false;
          this.clinicData.isEnableProductDiscount =
            typeof clinic.isEnableProductDiscount !== "undefined"
              ? clinic.isEnableProductDiscount
              : false;
          this.clinicData.clinicDiscounts = clinic.clinicDiscounts
            ? clinic.clinicDiscounts
            : [];
          this.clinicData.clinicAssignedSuppliers =
            clinic.clinicAssignedSuppliers
              ? clinic.clinicAssignedSuppliers
              : [];
          this.clinicData.address = clinic.address;
          this.clinicData.status = clinic.status === "Active" ? false : true;
          this.checkDisplayAddress = clinic.address.displayAddress;
          this.clinicData.hasApprovedByAdmin = clinic.hasApprovedByAdmin;
          this.clinicData.emailVerifiedAt = clinic.emailVerifiedAt;
          this.clinicData.email = clinic.email;
          this.clinicData.paymentEmail =
            clinic.storeInvoiceEmail
              ? clinic.storeInvoiceEmail
              : clinic.orgOwner
                ? clinic.orgOwner.details[0].email
                : clinic.invoiceEmail
          this.clinicData.storeInvoiceEmail = clinic.storeInvoiceEmail
          this.clinicData.contactNumber = clinic.contactNumber
            ? clinic.contactNumber
            : "";
          this.clinicData.instagram = clinic.instagram ? clinic.instagram : "";
          this.clinicData.facebook = clinic.facebook ? clinic.facebook : "";
          this.clinicData.website = clinic.website ? clinic.website : "";
          this.clinicData.clinicAddress1 = clinic.clinicAddress1
            ? clinic.clinicAddress1
            : "";
          this.clinicData.isResidentialAddress = clinic.isResidentialAddress
            ? clinic.isResidentialAddress
            : "";
          this.clinicData.haveAdrenalineAndHyalaseInClinic =
            clinic.haveAdrenalineAndHyalaseInClinic
              ? clinic.haveAdrenalineAndHyalaseInClinic
              : "";
          this.clinicData.intendToSecureS4Medication =
            clinic.intendToSecureS4Medication
              ? clinic.intendToSecureS4Medication
              : "";
          this.clinicData.abnCertificate = clinic.abnCertificate
            ? clinic.abnCertificate
            : "";
          this.clinicData.councilPermits = clinic.councilPermits
            ? clinic.councilPermits
            : [];
          this.clinicData.poisonsCertificate = clinic.poisonsCertificate
            ? clinic.poisonsCertificate
            : [];
          this.clinicData.clinicOpeningHours = clinic.clinicOpeningHours
            ? clinic.clinicOpeningHours
            : {
              mondays: "",
              tuesdays: "",
              wednesdays: "",
              thursdays: "",
              fridays: "",
              saturdays: "",
              sundays: "",
            };
          this.clinicData.clinicFrontPhoto = clinic.clinicFrontPhoto
            ? clinic.clinicFrontPhoto
            : [];
          this.clinicData.clinicFloorPhoto = clinic.clinicFloorPhoto
            ? clinic.clinicFloorPhoto
            : [];
          this.clinicData.clinicTreatmentBedPhoto =
            clinic.clinicTreatmentBedPhoto
              ? clinic.clinicTreatmentBedPhoto
              : [];
          this.clinicData.clinicDrawingUpAreaPhoto =
            clinic.clinicDrawingUpAreaPhoto
              ? clinic.clinicDrawingUpAreaPhoto
              : [];
          this.clinicData.clinicFridgePhoto = clinic.clinicFridgePhoto
            ? clinic.clinicFridgePhoto
            : [];
          this.clinicData.clinicDrugStoragePhoto = clinic.clinicDrugStoragePhoto
            ? clinic.clinicDrugStoragePhoto
            : [];
          this.clinicData.clinicBinsPhoto = clinic.clinicBinsPhoto
            ? clinic.clinicBinsPhoto
            : [];
          this.clinicData.clinicRoomLockPhoto = clinic.clinicRoomLockPhoto
            ? clinic.clinicRoomLockPhoto
            : [];
          this.clinicData.clinicEpipenAndHyalasePhoto =
            clinic.clinicEpipenAndHyalasePhoto
              ? clinic.clinicEpipenAndHyalasePhoto
              : [];
          this.clinicData.isProfileCompleted =
            clinic.isProfileCompleted || false;
          this.clinicData.showStore = clinic.showStore || false;
          this.canAddPayment = clinic.showStore || false;
          this.clinicData.isDoctorStore = clinic.isDoctorStore || false;
          this.clinicData.orderRecipients = clinic.orderRecipients
            ? clinic.orderRecipients.join(",")
            : [];
          this.clinicData.isTestClinic = clinic.isTestClinic
            ? clinic.isTestClinic
            : false;
          this.clinicData.isDrugBookEnabled = clinic.isDrugBookEnabled
            ? clinic.isDrugBookEnabled
            : false;
          this.clinicData.isTrainingClinic = clinic.isTrainingClinic
            ? clinic.isTrainingClinic : false;
          this.clinicData.isStaffClinic = clinic.isStaffClinic
            ? clinic.isStaffClinic : false;
          this.clinicData.isDrugBookEnabled = clinic.isDrugBookEnabled
            ? clinic.isDrugBookEnabled
            : false;
          this.clinicData.isTaxable = clinic.isTaxable
            ? clinic.isTaxable
            : false;
          this.clinicData.paymentTypes = clinic.paymentTypes
            ? clinic.paymentTypes
            : [];
          this.clinicData.organizationId = clinic.organizationDetail
            ? clinic.organizationDetail._id
            : null;
          this.clinicData.invoiceEmail = clinic.invoiceEmail;
          this.clinicData.storeInvoiceEmail = clinic.storeInvoiceEmail;
          this.clinicData.isPOSEnabled = clinic.isPOSEnabled;
          this.clinicData.isInventoryEnabled = clinic.isInventoryEnabled;
          this.clinicData.isCreditCardFeeExempt = clinic.isCreditCardFeeExempt;
          this.clinicData.isBYODoctor = clinic.isBYODoctor
            ? clinic.isBYODoctor
            : false;
          return this.fetchSuppliers();
        })
        .then((suppliers) => {
          this.fetchInstantSuppliers();
          this.suppliers = suppliers.data.data;
        });
    },
    async setDefaultPayment() {
      const payload = { ...this.defaultPaymentMethod };
      this.defaultPaymentParams.userId = null;
      this.defaultPaymentParams.paymentMethod = null;
      this.defaultPaymentParams.cardId = null;
      this.popUpSetDefault = false;
      this.disabled = true;
      // this.$vs.loading({
      //   background: "danger",
      //   color: "#fff",
      //   container: "#set-payment-method",
      //   scale: 0.45,
      // });
      this.setDefaultPaymentMethod(payload)
        .then(async (response) => {
          this.disabled = false;
          // this.$vs.loading.close("#set-payment-method > .con-vs-loading");
          this.$vs.notify({
            title: "Success",
            text: "Payment details updated successfully.",
            color: "success",
          });
          let clinicId = this.$route.params.clinicId;
          await this.getPaymentDetails();
          await this.getClinicDetail(clinicId);
        })
        .catch(() => {
          this.disabled = false;
          // this.$vs.loading.close("#set-payment-method > .con-vs-loading");
          this.$vs.notify({
            title: "Failed",
            text: "Oops! something went wrong",
            color: "danger",
          });
        });
    },
    async removePaymentDetails(deleteParams) {
      this.removeBankDetails(deleteParams);
    },
    removeBankDetails(data) {
      this.popUpDelete = false;
      this.disabled = true;
      // this.$vs.loading({
      //   background: "danger",
      //   color: "#fff",
      //   container: "#delete-bank-details",
      //   scale: 0.45,
      // });

      this.removeUserBankDetails({ ...data })
        .then(async (response) => {
          this.popUpDelete = false;
          this.disabled = false;
          // this.$vs.loading.close("#delete-bank-details > .con-vs-loading");
          this.$vs.notify({
            title: "Success",
            text: "Payment details updated successfully.",
            color: "success",
          });
          this.clinicData.financialDetails = {};
          let clinicId = this.$route.params.clinicId;
          await this.getPaymentDetails();
          await this.getClinicDetail(clinicId);
          this.deleteParams.isCard = null;
          this.deleteParams._id = null;
        })
        .catch(() => {
          this.popUpDelete = false;
          this.disabled = false;
          // this.$vs.loading.close("#delete-bank-details > .con-vs-loading");
          this.$vs.notify({
            title: "Failed",
            text: "Oops! something went wrong",
            color: "danger",
          });
        });
    },
    removeCardDetails(deleteParams) {
      this.popUpDelete = false;
      this.disabled = true;
      // this.$vs.loading({
      //   background: "danger",
      //   color: "#fff",
      //   container: "#delete-card-details",
      //   scale: 0.45,
      // });

      this.removeCreditCardDetails({
        userId: deleteParams._id,
        cardId: deleteParams.cardId,
        type: "clinic",
      })
        .then(async (response) => {
          this.popUpDelete = false;
          this.disabled = false;
          // this.$vs.loading.close("#delete-card-details > .con-vs-loading");
          this.$vs.notify({
            title: "Success",
            text: "Payment details updated successfully.",
            color: "success",
          });
          this.clinicData.financialDetails = {};
          let clinicId = this.$route.params.clinicId;
          await this.getPaymentDetails();
          await this.getClinicDetail(clinicId);
          this.deleteParams.isCard = null;
          this.deleteParams._id = null;
        })
        .catch(() => {
          this.popUpDelete = false;
          this.disabled = false;
          // this.$vs.loading.close("#delete-card-details > .con-vs-loading");
          this.$vs.notify({
            title: "Failed",
            text: "Oops! something went wrong",
            color: "danger",
          });
        });
    },
    async getDiscountList() {
      await this.fetchDiscountList().then((response) => {
        this.discounts = response.data.data;
      });
    },
    onSuccessfullyVerified(verifiedAt) {
      this.clinicData.emailVerifiedAt = verifiedAt;
    },
    async saveData() {
      let isValid = await this.$validator.validateAll("basic");

      // if (
      //   this.$store.state.AppActiveUser.userType !== "superAdmin" &&
      //   this.$store.state.AppActiveUser.userType !== "admin" &&
      //   this.$store.state.AppActiveUser.userType !== "franchise" &&
      //   this.$store.state.AppActiveUser.userType !== "subFranchise"
      // ) {
      //   isValid = await this.$validator.validate();
      //   isValid = await this.$refs.stepTwoRef.$validator.validate();
      //   isValid = await this.$refs.stepThreeRef.$validator.validate();
      //   isValid = await this.$refs.step4Ref.$validator.validate();
      //   isValid = await this.$refs.step5Ref.$validator.validate();
      //   isValid = await this.$refs.step6Ref.$validator.validate();
      //   isValid = await this.$refs.step7Ref.$validator.validate();
      //   isValid = await this.$refs.step8Ref.$validator.validate();
      //   isValid = await this.$refs.step9Ref.$validator.validate();
      //   isValid = await this.$refs.step10Ref.$validator.validate();
      //   isValid = await this.$refs.step11Ref.$validator.validate();

      //   if (this.clinicData.isResidentialAddress === "") {
      //     isValid = false;
      //     this.validationKeys.showIsResidentialAddressError = true;
      //   } else {
      //     this.validationKeys.showIsResidentialAddressError = false;
      //   }

      //   if (this.clinicData.intendToSecureS4Medication === "") {
      //     isValid = false;
      //     this.validationKeys.showIntendToSecureS4MedicationError = true;
      //   } else {
      //     this.validationKeys.showIntendToSecureS4MedicationError = false;
      //   }

      //   if (this.clinicData.haveAdrenalineAndHyalaseInClinic === "") {
      //     isValid = false;
      //     this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = true;
      //   } else {
      //     this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = false;
      //   }

      //   if (this.clinicData.abnCertificate == "") {
      //     this.errors.add({
      //       field: "abnCertificate",
      //       msg: "ABN Certificate is required",
      //     });
      //     document.getElementById("abnCertificateId").style.borderColor =
      //       "#ea5455";
      //     isValid = false;
      //   } else {
      //     document.getElementById("abnCertificateId").style.borderColor =
      //       "#cccccc";
      //     this.errors.remove("abnCertificate");
      //   }

      //   if (this.clinicData.clinicFrontPhoto.length === 0) {
      //     this.$refs.stepThreeRef.errors.add({
      //       field: "clinicFrontPhoto",
      //       msg: "Clinic Front Photo is required",
      //     });
      //     document.getElementById("clinicFrontPhoto").style.borderColor =
      //       "#ea5455";
      //     isValid = false;
      //   } else {
      //     document.getElementById("clinicFrontPhoto").style.borderColor =
      //       "#cccccc";
      //     this.$refs.stepThreeRef.errors.remove("clinicFrontPhoto");
      //   }

      //   if (this.clinicData.clinicFloorPhoto.length === 0) {
      //     this.$refs.step4Ref.errors.add({
      //       field: "clinicFloorPhoto",
      //       msg: "Clinic Floor Photo is required",
      //     });
      //     document.getElementById("clinicFloorPhoto").style.borderColor =
      //       "#ea5455";
      //     isValid = false;
      //   } else {
      //     document.getElementById("clinicFloorPhoto").style.borderColor =
      //       "#cccccc";
      //     this.$refs.step4Ref.errors.remove("clinicFloorPhoto");
      //   }

      //   if (this.clinicData.clinicTreatmentBedPhoto.length === 0) {
      //     this.$refs.step5Ref.errors.add({
      //       field: "clinicTreatmentBedPhoto",
      //       msg: "Clinic Treatment Bed Photo is required",
      //     });
      //     document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
      //       "#ea5455";
      //     isValid = false;
      //   } else {
      //     document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
      //       "#cccccc";
      //     this.$refs.step5Ref.errors.remove("clinicTreatmentBedPhoto");
      //   }

      //   if (this.clinicData.clinicDrawingUpAreaPhoto.length === 0) {
      //     this.$refs.step6Ref.errors.add({
      //       field: "clinicDrawingUpAreaPhoto",
      //       msg: "Clinic Drawing Up Area Photo is required",
      //     });
      //     document.getElementById(
      //       "clinicDrawingUpAreaPhoto"
      //     ).style.borderColor = "#ea5455";
      //     isValid = false;
      //   } else {
      //     document.getElementById(
      //       "clinicDrawingUpAreaPhoto"
      //     ).style.borderColor = "#cccccc";
      //     this.$refs.step6Ref.errors.remove("clinicDrawingUpAreaPhoto");
      //   }

      //   if (this.clinicData.clinicFridgePhoto.length === 0) {
      //     this.$refs.step7Ref.errors.add({
      //       field: "clinicFridgePhoto",
      //       msg: "Clinic Fridge Photo is required",
      //     });
      //     document.getElementById("clinicFridgePhoto").style.borderColor =
      //       "#ea5455";
      //     isValid = false;
      //   } else {
      //     document.getElementById("clinicFridgePhoto").style.borderColor =
      //       "#cccccc";
      //     this.$refs.step7Ref.errors.remove("clinicFridgePhoto");
      //   }

      //   if (this.clinicData.clinicDrugStoragePhoto.length === 0) {
      //     this.$refs.step8Ref.errors.add({
      //       field: "clinicDrugStoragePhoto",
      //       msg: "Clinic Drug Storage Photo is required",
      //     });
      //     document.getElementById("clinicDrugStoragePhoto").style.borderColor =
      //       "#ea5455";
      //     isValid = false;
      //   } else {
      //     document.getElementById("clinicDrugStoragePhoto").style.borderColor =
      //       "#cccccc";
      //     this.$refs.step8Ref.errors.remove("clinicDrugStoragePhoto");
      //   }

      //   if (this.clinicData.clinicBinsPhoto.length === 0) {
      //     this.$refs.step9Ref.errors.add({
      //       field: "clinicBinsPhoto",
      //       msg: "Clinic Bins Photo is required",
      //     });
      //     document.getElementById("clinicBinsPhoto").style.borderColor =
      //       "#ea5455";
      //     isValid = false;
      //   } else {
      //     document.getElementById("clinicBinsPhoto").style.borderColor =
      //       "#cccccc";
      //     this.$refs.step9Ref.errors.remove("clinicBinsPhoto");
      //   }
      // }

      // if (
      //   !this.clinicData.address.geometry.coordinates[0] ||
      //   this.checkDisplayAddress != this.clinicData.address.displayAddress
      // ) {
      //   isValid = false;
      //   this.errors.add({
      //     field: "displayAddress",
      //     msg: "Enter valid address.",
      //   });
      // } else {
      //   this.errors.remove("displayAddress");
      // }
      if (isValid) {
        this.$vs.loading();
        const data = {
          ...this.clinicData,
          orderRecipients:
            this.clinicData.orderRecipients &&
              this.clinicData.orderRecipients.length > 0
              ? this.clinicData.orderRecipients.split(",")
              : "",
        };
        await this.updateClinicDetail({
          id: this.clinicId,
          data,
        })
          .then(async (res) => {
            // this.clinicData.emailVerifiedAt = res.data.data.emailVerifiedAt;
            this.$vs.notify({
              title: "Success",
              text: "Clinic updated successfully.",
              color: "success",
            });
            await this.getPaymentDetails();
            await this.getClinicDetail(this.clinicId);
            this.statusChangedClick = false;
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.notify({
              title: "Failed",
              text: "Failed to update clinic. Try again!",
              color: "danger",
            });
            this.statusChangedClick = false;

            if (err.status === 422) {
              this.mapServerErrors(this, err, "basic");
            }
            if (err.status === 417) {
              if (err.data.title === "Invalid email") {
                this.errors.add({
                  field: "email",
                  msg: err.data.message,
                  scope: "basic",
                });
              } else if (err.data.title === "Invalid ABN") {
                this.errors.add({
                  field: "abn",
                  msg: err.data.message,
                  scope: "basic",
                });
              }
            }

            this.$vs.loading.close();
          });
      }
    },
    async changeAdminApproval(status) {
      this.adminApprovalChangeClick = true;
      await this.updateClinicDetail({
        id: this.clinicId,
        data: { hasApprovedByAdmin: !status },
      })
        .then((res) => {
          this.$vs.notify({
            title: "Success",
            text: "Admin approval updated successfully.",
            color: "success",
          });
          this.adminApprovalChangeClick = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update admin approval. Try again!",
            color: "danger",
          });
          this.adminApprovalChangeClick = false;
        });
    },
    async getPaymentDetails() {
      const id = await this.$route.params.clinicId;
      const res = await this.fetchAccountPaymentDetailsList({
        id,
        idType: "clinicId",
      });
      if (res.data.data.length > 0) {
        this.paymentDetailsList = res.data.data;
        let isDefault = res.data.data.some(e => e.isDefault);
        let isPaymentTerms = res.data.data.filter(e => e.isPaymentTerm);
        if (!isDefault && this.paymentDetailsList.length >= 1 && this.paymentDetailsList.length !== isPaymentTerms.length) {
            this.defaultPaymentMethod = {
              ...this.paymentDetailsList.filter(e => !e.isPaymentTerm)[0],
              userId: this.$route.params.clinicId,
            };
            this.setDefaultPayment();
        }
      }
    },
    async changeDiscountEnable(status) {
      this.discountEnableChangeClick = true;
      await this.updateClinicDetail({
        id: this.clinicId,
        data: { isEnableProductDiscount: !status },
      })
        .then((res) => {
          this.$vs.notify({
            title: "Success",
            text: "Discount enable updated successfully.",
            color: "success",
          });
          this.discountEnableChangeClick = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update discount enable. Try again!",
            color: "danger",
          });
          this.discountEnableChangeClick = false;
        });
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
    async getPaymentTypes() {
      try {
        const res = await this.getSettingsByKey("paymentOptionTypes");
        this.paymentTypesOptions = res.data.data.value;
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    isRemoveEnabled() {
      let isPaymentTerms = this.paymentDetailsList.filter(e => e.isPaymentTerm)
      return this.$store.state.AppActiveUser.userType === "superAdmin" ||
      (this.paymentDetailsList.length && this.paymentDetailsList.length !== isPaymentTerms.length
      && (this.paymentDetailsList.length - isPaymentTerms.length) !== 1)
    },
    isCCorDebitEnabled() {
      if(this.clinicData.paymentTypes) {
        return this.clinicData.paymentTypes.length > 0 && this.clinicData.paymentTypes.some(e => ["creditCard", "DebitCard"].includes(e))
      }

      return []
    }
  },
  mounted() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: "au" } }
    );
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      this.errors.remove("displayAddress");
      let ac = place.address_components;
      this.addressData = place.address_components;
      this.checkDisplayAddress = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      this.clinicData.address.displayAddress = place.formatted_address;
      this.clinicData.address.geometry.coordinates[0] = lon;
      this.clinicData.address.geometry.coordinates[1] = lat;

      this.clinicData.address.suburb = "";
      this.clinicData.address.state = "";
      this.clinicData.address.postCode = "";
      if (
        place &&
        place.address_components &&
        place.address_components.length > 0
      ) {
        place.address_components.forEach(function (gAdd) {
          if (gAdd.types[0] === "administrative_area_level_2") {
            this.clinicData.address.suburb = gAdd.long_name;
            this.errors.remove("clinicSuburb");
          }
          if (gAdd.types[0] === "administrative_area_level_1") {
            this.clinicData.address.state = gAdd.long_name;
            this.errors.remove("clinicState");
          }
          if (gAdd.types[0] === "postal_code") {
            this.clinicData.address.postCode = gAdd.long_name;
            this.errors.remove("clinicPostCode");
          }
        });
      }
    });
  },
  async created() {
    this.clinicId = await this.$route.params.clinicId;
    await this.getOrganizationList(this.$store.state.AppActiveUser._id);
    await this.getPaymentDetails();
    await this.getClinicDetail(this.clinicId);
    this.isRequired =
      this.$store.state.AppActiveUser.userType === "superAdmin" ||
        this.$store.state.AppActiveUser.userType === "admin" ||
        this.$store.state.AppActiveUser.userType === "franchise" ||
        this.$store.state.AppActiveUser.userType === "subFranchise"
        ? false
        : true;
    await this.getDiscountList();
    let userType = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
    );
    this.role = userType === null;
    this.account = userType;
    this.getPaymentTypes();
  },
  watch: {
    loading: function(isLoading) {
      if (isLoading) {
        return this.$vs.loading();
      }
      this.$vs.loading.close();
    },
    "clinicData.isResidentialAddress": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showIsResidentialAddressError = false;
      }
    },
    "clinicData.intendToSecureS4Medication": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showIntendToSecureS4MedicationError = false;
      }
    },
    "clinicData.haveAdrenalineAndHyalaseInClinic": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = false;
      }
    },
  },
  beforeDestroy() {
    EventBus.$emit("closePopup");
  },
};
</script>

<style scoped>
.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3;
  filter: grayscale(1);
}

.error-text {
  font-size: 0.65rem !important;
  padding: 2px 4px 4px;
  display: block;
  margin-top: -6px;
}

.no-margin-span {
  margin-top: 0 !important;
}

.account-payment-setting {
  overflow-wrap: anywhere;
}
</style>
